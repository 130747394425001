
import React, { useState } from 'react';
import { Space, Table, Button, Modal } from 'antd';
import { Link } from "react-router-dom";



function CustomerPropertyList() {
    const customer = [
        {
            title: 'project',
            dataIndex: 'project',
            key: 'project',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Property',
            dataIndex: 'price',
            key: 'project',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Plot No',
            dataIndex: 'emp',
            key: 'project',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Purchased Date',
            dataIndex: 'date',
            key: 'project',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Square yards',
            dataIndex: 'status',
            key: 'project',
            render: (text) => <span>{text}</span>,
        },
      
       
        {
            title: 'Emp ID',
            dataIndex: 'ptype',
            key: 'ptype',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Payment Method',
            dataIndex: 'status',
            key: 'project',
            render: (text) => <span>{text}</span>,
        },

        {
            title: 'Price',
            dataIndex: 'status',
            key: 'project',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'project',
            render: (text) => <span>{text}</span>,
        },
        {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Link  to='/editcustomerdetails' ><span class="material-symbols-outlined ">
                      edit
                  </span> </Link>
        <a><span class="material-symbols-outlined ">
                      delete
                  </span></a>
      </Space>
    ),
  },

    ];
    const customerdata = [
        {
            key: '1',
            id: "1",
            emp: 'John',
            date: '15/06/2024',
            ptype: "sale",
            project: "Project 1",
            location: "Hyderabd",
            price: "$500",
            empid: "009/HYD",
            status: "pending"
        },

    ];
    const [selectionType, setSelectionType] = useState('checkbox');
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
          disabled: record.name === 'Disabled User',
          // Column configuration not to be checked
          name: record.name,
        }),
      };
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showPlotModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
      
        const handleCancel = () => {
          setIsModalOpen(false);
        };


   
    return (
<>
                    <section className='top-gutter-40'>

                    <Table className='bottom-gutter-40'
                     rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                    columns={customer}
                    dataSource={customerdata} pagination={false}
                />
                        
                    </section>


        </>
    );
}

export default CustomerPropertyList;
