import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Select } from "antd";
import plotimage from "../../assets/images/r-banner-4.jpeg";
import { Link } from "react-router-dom";
import {
  getProjectDropdownList,
  getPropertiesDropdownList,
  formatPrice,
} from "../../services/services";

function PlotList(props) {
  const { plots, isAdmin } = props;
  const rawPlots = plots;
  const [plotsData, setPlotsData] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [propertiesList, setPropertiesList] = useState([]);

  const handleViewClick = (plot) => {
    window.location.replace("/propertydetails?plot_id=" + plot.plot_id);
    return;
  };
  const handleEditClick = (plot) => {
    window.location.replace("/addplot?plot_id=" + plot.plot_id);
    return;
  };
  const fetchProjects = () => {
    const data = getProjectDropdownList(rawPlots);
    console.log(data);
    setProjectList(data);
  };

  useEffect(() => {
    localStorage.setItem("bookedPlots", []);
    setPlotsData(plots);
    fetchProjects();
  }, [plots]);
  const planTypes = [
    { value: "planA", label: <span>Plan A</span> },
    { value: "planB", label: <span>Plan B</span> },
    { value: "Other", label: <span>Book a new unit</span> },
  ];
  const planStyle = {
    display: "none",
  };

  const handlePlotSelect = (e, plot) => {
    // setIsModalOpen(true);
    const checkStatus = e.target.checked;
    const plotId = e.target.value;
    if (checkStatus) document.getElementById(plotId).style.display = "block";
    else document.getElementById(plotId).style.display = "none";

    const bookedPlotsFromStore = localStorage.getItem("bookedPlots");
    let plots = bookedPlotsFromStore ? JSON.parse(bookedPlotsFromStore) : [];
    let obj = {
      plotId: plot.plot_id,
      planType: "",
    };
    if (plots.length == 0 && checkStatus) {
      plots.push(obj);
      localStorage.setItem("bookedPlots", JSON.stringify(plots));
      return;
    } else if (plots.length > 0) {
      var index = plots.map((item) => item.plotId).indexOf(plot.plot_id);
      if (index == -1) {
        if (checkStatus) {
          plots.push(obj);
        }
      } else if (index >= 0) {
        if (checkStatus) return;
        else plots.splice(index, 1);
      }
      localStorage.setItem("bookedPlots", JSON.stringify(plots));
      return;
    }
  };

  const handleChangePlantype = (value, id) => {
    const plots = JSON.parse(localStorage.getItem("bookedPlots"));
    var index = plots.map((item) => item.plotId).indexOf(id);
    plots[index].planType = value;
    localStorage.setItem("bookedPlots", JSON.stringify(plots));
    return;
  };
  const PlotListGrid = (props) => {
    const a = props.plots;
    const listItems = a.map((plot) => (
      <article
        className={
          plot.plot_status == "Booked" || plot.plot_status == "Sold"
            ? "plot-details sold"
            : "plot-details"
        }
      >
        {isAdmin && plot.plot_status == "Available" && (
          <Checkbox
            className="selectplotcheckbox"
            value={plot.plot_id}
            onClick={(e) => handlePlotSelect(e, plot)}
          ></Checkbox>
        )}
        <figure>
          <img src={plotimage} />
          <figcaption>
            <small>Plot No:</small>
            {plot.plot_number}
          </figcaption>
          {/* <strong>₹ {plot.total_price}</strong> */}
        </figure>
        <div className="flex flex-column plotde">
          <div class="flex-row">
            <dl>
              <dt>Project</dt>
              <dd>{plot.project_name}</dd>
            </dl>
            <dl>
              <dt>Property</dt>
              <dd>{plot.property_name}</dd>
            </dl>
          </div>

          <dl class="planType" style={planStyle} id={plot.plot_id}>
            <dt>Plan</dt>
            <dd>
              <Select
                options={planTypes}
                onSelect={(value) => handleChangePlantype(value, plot.plot_id)}
              ></Select>
            </dd>
          </dl>
          <div class="flex-row">
            <dl>
              <dt>Area</dt>
              <dd>
                {plot.cents} cents ({plot.square_yards} Sq.yds)
              </dd>
            </dl>
            <dl>
              <dt>Address</dt>
              <dd>
                {plot.address1}, {plot.address2}, {plot.city}, {plot.state}
              </dd>
            </dl>
          </div>
          <div class="flex-row">
            <dl>
              <dt>Price</dt>
              <dd>
                {" "}
                <strong> {formatPrice(plot.total_price)}</strong>
              </dd>
            </dl>
            <dl>
              <dt>Status</dt>
              <dd>
                <strong>{plot.plot_status}</strong>
              </dd>
            </dl>
          </div>

          {!isAdmin && (
            <Link
              onClick={() => handleViewClick(plot)}
              className="ant-btn-primary"
            >
              View Details
            </Link>
          )}
          {isAdmin && plot.plot_status == "Available" && (
            <Link
              onClick={() => handleEditClick(plot)}
              className="ant-btn-primary"
            >
              Edit Details
            </Link>
          )}
        </div>
      </article>
    ));
    return <div className="flex-row wrap mob-flex-row">{listItems}</div>;
  };

  const handleSubmit = async (values) => {
    try {
      var data = rawPlots.filter((item) => {
        return (
          item.project_id == values.project &&
          item.property_id == values.property
        );
      });
      setPlotsData(data);
    } catch {
      console.log("Catch block api error!");
    }
  };

  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };

  const handleProjectChange = (project) => {
    var data = rawPlots.filter((item) => {
      return item.project_id == project;
    });
    const propertyList = getPropertiesDropdownList(data);
    setPropertiesList(propertyList);
  };
  if (plotsData.length > 0)
    return (
      <>
        <h2>Plots Details</h2>
        <Form
          className="form flex-row nowidth vcenter horizontal-form plotfilter"
          onFinish={handleSubmit}
          onFinishFailed={handleErrors}
        >
          <Form.Item
            className="cell "
            label="Project"
            name="project"
            rules={[
              {
                required: true,
                message: "Please select project",
              },
            ]}
          >
            <Select
              options={projectList}
              onSelect={(value) => handleProjectChange(value)}
            ></Select>
          </Form.Item>
          <Form.Item
            className="cell"
            label="Property"
            name="property"
            rules={[
              {
                required: true,
                message: "Please property",
              },
            ]}
          >
            <Select options={propertiesList}></Select>
          </Form.Item>
          <Button htmlType="submit" type="primary">
            Search
          </Button>
        </Form>

        <div className="flex-row">
          <div className="flex-column bottom-gutter-20 cell right-gutter-40">
            <div className="flex-column  propertylist top-gutter-40 ">
              <div className="flex-row wrap">
                <PlotListGrid plots={plotsData} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default PlotList;
