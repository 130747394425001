import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Button,
  Checkbox,
  Select,
  Form,
  Input,
  DatePicker,
  CollapseProps,
  UploadProps,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getDataWithPayload } from "../../../services/services";
import { employeeDetailsByIDAPI } from "../../../common/constants";

import AdminHeader from "../shared/header";
import SharedEditEmployer from "../../shared/addeditemployer";
import { Collapse } from "antd";
function EmployeeViewDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [empID, setEmpID] = useState("");
  const [data, setData] = useState({});
  async function fetchEmployeeData(id) {
    const emp_id = id.replace("_", "/");
    const res = await getDataWithPayload(employeeDetailsByIDAPI, {
      emp_id: emp_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      setData(response.data);
    }
  }

  useEffect(() => {
    const emp_id = searchParams.get("emp_id");
    setEmpID(emp_id);
    if (emp_id) fetchEmployeeData(emp_id);
  }, []);
  
  return (
    <div className="admin ">
      <div className="flex flex-row fullheight">
        <div className="admin-dashboard">
          <AdminHeader></AdminHeader>

          <div className="flex-row cell">
            <div className="bodycontainer emp-view">
              <div className="flex flex-row fullheight ">
                <div className="flex flex-row fullheight ">
                  <figure className="cell top">
                    <img src={'https://srilakshmiagrofarms.co.in/webapplication/uploads/employees/'+data.profile_pic} alt="" />
                    <figcaption>
                      {data.name} <small>{data.role}</small>
                    </figcaption>
                  </figure>
                </div>
                <div className="accordion cell">
                  <SharedEditEmployer empID={empID}></SharedEditEmployer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeViewDetails;
