import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Tag,
  Modal,
  Button,
  Checkbox,
  Form,
  Input,
  Spin,
  Select,
} from "antd";
import logo from "../../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import AdminHeader from "../shared/header";
import AdminFooter from "../shared/footer";
import PurchaseScheme from "../../shared/purchasescheme";
import PlotList from "../../shared/plotlist";
import CustomerHeader from '../shared/header';
// import EditEmployee from '../modal/editemployee';
import { getData, deleteData } from "../../../services/services";
import { adminPlotsListAPI, deleteProjectAPI } from "../../../common/constants";
import PlotRegistration from "../../shared/plotregistration";

function CustomerPurchaseCart() {

  return (
    <div className='customer'>
    <div className="customerdashboard flex flex-row cell">
        <CustomerHeader></CustomerHeader>
        <div className='customerbody '>
            <h1>Your Cart</h1>
            <PlotRegistration></PlotRegistration>               
        </div>
    </div>

</div>

  );
}

export default CustomerPurchaseCart;
