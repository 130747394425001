import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Spin, message } from "antd";
import EmployeeHeader from "../../employee/shared/header";
import logo from '../../../assets/images/logoonly.png';
import { Link } from "react-router-dom";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import { errorMessage } from "../../../common/constants";
import { updateCustomerPasswordAPI } from "../../../common/constants";
import logotitle from '../../../assets/images/logotitle.png';
function CustomerResetPassword() {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //Form submit method
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      setHasError(false);
      const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
      values.cust_id = customerDetails.customer_id;
      delete values.confirmpassword;
      if(values.remember) delete values.remember;
      const response = await fetch(updateCustomerPasswordAPI, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result && result.status === 'success') {       
        setIsLoading(false);

        message.success('Password updated successfully!')
        window.location.replace("/CustomerDashboard");
      } else {
        setHasError(true);
        setIsLoading(false);
        console.log("api failure");
      }
    } catch {
      setIsLoading(false);
      setHasError(true);
      console.log("Catch block api error!");
    }
  };

  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };

  return (
    <div className="customer">
      <div className="customerlogin flex flex-column cell mob-flex-row">
      <div className="logostyles">
          <figure className="logo" ><img src={logo} /></figure>
          <figure className="logotitle"><img src={logotitle} /></figure>
        </div>
        <Form
          className="form"
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleErrors}
        >

          <div className="flex middle">
            <span class="material-symbols-outlined size-72">
              account_circle
            </span>
          </div>
          <h2>
            {" "}
            <span className="cell text-center">Reset Password</span>
          </h2>
          <Form.Item
            label="Password"
            name="currentpassword"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newpassword"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
        name="confirmpassword"
        label="Confirm Password"
        dependencies={['newpassword']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please input your confirm password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newpassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The new password that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

          <div className="flex flex-row vcenter bottom-gutter">
            <Form.Item
              className="no-gutter"
              name="remember"
              valuePropName="checked"
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
           
          </div>
          {hasError && <ErrorComponent errorMessage={errorMessage} />}
          {isLoading && <Spin />}
          <Form.Item className="flex middle">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>

      {/* <EmployeeFooter></EmployeeFooter> */}
    </div>
  );
}

export default CustomerResetPassword;
