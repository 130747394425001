
import React, { useState } from 'react';
import logo from '../../assets/images/logoonly.png';
import logotitle from '../../assets/images/logotitle.png';
import ConfirmPayment from './confirmpayment';


function PaymentView() {

    return (
        <div className='customer'>
              <div className="logostyles flow-row">
        <figure className="logo" ><img src={logo} /></figure>
        <figure className="logotitle"><img src={logotitle} /></figure>
      </div>
            <div className="customerdashboard flex flex-row cell">

                <ConfirmPayment></ConfirmPayment>
            </div>

        </div>
    );
}

export default PaymentView;
