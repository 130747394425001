// API names
export const registerAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/register-data";
export const locationAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/locations-list";
export const employeesListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-employees-list";
export const adminEmployeesListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-employees-list-admin";
export const loginAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/user-login";
export const updatePasswordAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/update-password";
export const employeesListByRefAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-reference-employees-list";
export const deleteEmployyeAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/delete-employee";
export const employeeDetailsByIDAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-employee-details";
export const updateEmployeeAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/update-employee-details";
export const addProjectAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/add-projects";
export const projectsListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-projects-list";
export const adminProjectsListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-projects-list-admin";
export const deleteProjectAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/delete-projects";
export const updateProjectAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/update-projects";
export const projectDetailsByIDAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-project-details";
export const updatePropertyAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/update-property";
export const addPropertyAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/add-property";
export const propertiesListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-property-list";
export const adminPropertiesListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-property-list-admin";
export const deletePropertyAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/delete-property";
export const propertyDetailsByIDAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-property-details";
export const employeesPaymentHistoryAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-order-payments-data-employee";
export const updatePlotAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/update-plot";
export const addPlotAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/add-plot";
export const adminPlotsListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-plot-list-admin";
export const getPlotDetailsByIDAPI =
  " https://srilakshmiagrofarms.co.in/webapplication/api/get-plot-details";
export const plotsAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-plot-list";
export const plotDetailsByIDAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-plot-details";
export const employeeTeamBusinessAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/teambusiness-based-on-reference-id";
export const getCommissionDataAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/commission-list-admin";
  export const getCommissionDataByIdAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/commission-list-with-receipt-id  ";

//roles
export const addRoleAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/add-role";
export const adminRolesListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-role-list-admin";
export const roleDetailsByIdAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-role-details";
export const updateRoleAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/update-role";
export const deleteRoleAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/delete-role";

//Customer
export const deleteCustomerAPI = "testingapi";
export const customerDetailsByIDAPI =
  " https://srilakshmiagrofarms.co.in/webapplication/api/get-customer-details";
export const customersAdminListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-customer-list-admin";
export const updateCustomerAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/update-customer";
export const registerCustomerAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/add-customer";
export const customerLoginAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/customer-login";
export const updateCustomerPasswordAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/update-password-customer";

// cart
export const addToCartAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/add-to-cart";
export const getCartDetailsAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-cart-data";
export const cartDetailsBasedOnIDAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-cart-data-customer";

//order details
export const addOrderDetailsAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/add-order-data";
export const ordersListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-order-payments-data";
export const adminOrderListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-cart-data-admin";
export const orderDetailsByIDAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-order-data-based-orderid";
export const updateOrderDetailsAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/update-order-data-plot";

//Receipts
export const emiListAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-receipts-data";
export const emiDetailsByIDAPI =
  "https://srilakshmiagrofarms.co.in/webapplication/api/get-receipts-data-single";
export const updateComissionPayAPI = "https://srilakshmiagrofarms.co.in/webapplication/api/commission-paying-status"

export const errorMessage = "Please try again. Facing issue in API";

// states dropdown values
export const states = [
  {
    value: "AN",
    label: "Andaman and Nicobar Islands",
  },
  {
    value: "AP",
    label: "Andhra Pradesh",
  },
  {
    value: "AR",
    label: "Arunachal Pradesh",
  },
  {
    value: "AS",
    label: "Assam",
  },
  {
    value: "BR",
    label: "Bihar",
  },
  {
    value: "CG",
    label: "Chandigarh",
  },
  {
    value: "CH",
    label: "Chhattisgarh",
  },
  {
    value: "DH",
    label: "Dadra and Nagar Haveli",
  },
  {
    value: "DD",
    label: "Daman and Diu",
  },
  {
    value: "DL",
    label: "Delhi",
  },
  {
    value: "GA",
    label: "Goa",
  },
  {
    value: "GJ",
    label: "Gujarat",
  },
  {
    value: "HR",
    label: "Haryana",
  },
  {
    value: "HP",
    label: "Himachal Pradesh",
  },
  {
    value: "JK",
    label: "Jammu and Kashmir",
  },
  {
    value: "JH",
    label: "Jharkhand",
  },
  {
    value: "KA",
    label: "Karnataka",
  },
  {
    value: "KL",
    label: "Kerala",
  },
  {
    value: "LD",
    label: "Lakshadweep",
  },
  {
    value: "MP",
    label: "Madhya Pradesh",
  },
  {
    value: "MH",
    label: "Maharashtra",
  },
  {
    value: "MN",
    label: "Manipur",
  },
  {
    value: "ML",
    label: "Meghalaya",
  },
  {
    value: "MZ",
    label: "Mizoram",
  },
  {
    value: "NL",
    label: "Nagaland",
  },
  {
    value: "OR",
    label: "Odisha",
  },
  {
    value: "PY",
    label: "Puducherry",
  },
  {
    value: "PB",
    label: "Punjab",
  },
  {
    value: "RJ",
    label: "Rajasthan",
  },
  {
    value: "SK",
    label: "Sikkim",
  },
  {
    value: "TN",
    label: "Tamil Nadu",
  },
  {
    value: "TS",
    label: "Telangana",
  },
  {
    value: "TR",
    label: "Tripura",
  },
  {
    value: "UK",
    label: "Uttar Pradesh",
  },
  {
    value: "UP",
    label: "Uttarakhand",
  },
  {
    value: "WB",
    label: "West Bengal",
  },
];

export const gstPercentage = 10;
