import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Spin } from "antd";
import logo from "../../../assets/images/logoonly.png";
import { Link } from "react-router-dom";
import { Login } from "../../../services/services";
import logotitle from '../../../assets/images/logotitle.png';
import { loginAPI, errorMessage } from "../../../common/constants";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";

function AdminLogin() {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //Form submit method
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      setHasError(false);
      if (values?.remember) {
        delete values.remember;
      }
      const response = await Login(loginAPI, values);
      const result = await response.json();
      if (result && result.userstatus === 1 && result.isAdmin === 1) {
        const userAdminDetails = {
          id: result.data.id,
          name: result.data.name,
          emp_id: result.data.emp_id,
          isAdmin: true,
        };
        localStorage.setItem("adminUserDetails", JSON.stringify(userAdminDetails));
        setIsLoading(false);
        window.location.replace("/teamlist");
        return;
      } else {
        setHasError(true);
        setIsLoading(false);
        console.log("api failure");
      }
    } catch {
      setIsLoading(false);
      setHasError(true);
      console.log("Catch block api error!");
    }
  };

  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };

  return (
    <div className="admin">
      <div className="customerlogin flex flex-column cell">
        <div className="logostyles">
          <figure className="logo">
            <img src={logo} />
          </figure>
          <figure className="logotitle"><img src={logotitle}  /></figure>
        </div>
        <Form
          className="form"
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleErrors}
        >
          <div className="flex middle">
          
          </div>
          <h2>
          <span class="material-symbols-outlined size-50">
              account_circle
            </span>
            <span className="text-center">Login</span>
          </h2>
          <Form.Item
            label="Username"
            name="emp_id"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div className="flex flex-row vcenter bottom-gutter">
            {/* <Form.Item
              className="no-gutter"
              name="remember"
              valuePropName="checked"
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item> */}
            {/* <span className='cell text-right'>If you are not having account, <Link to="/registration">Sign Up</Link></span> */}
          </div>
          <Form.Item className="flex middle">
            {hasError && <ErrorComponent errorMessage={"Issue in login(API issue/Not a admin/Wrong credentials)"} />}
          {isLoading && <Spin />}
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AdminLogin;
