
import React, { useState } from 'react';
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  FileOutlined,
  PoundCircleOutlined,
  UserAddOutlined ,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'antd';
function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    {
      label: 'Team',
      key: 'SubMenu1',
      icon: <AppstoreOutlined />,
      children: [
        {
          label: (<Link to='/teamlist'>Agent List</Link>),

        },
        // {
        //   label: (<Link to='/soldplots'>Properties Sold</Link>),

        // },
        {
          label: (<Link to='/rolemaintenance'>Agent Designation Maintenance</Link>),

        },
     
      
      ],
    },
    {
      label: 'Customer',
      key: 'SubMenu2',
      icon:<UserAddOutlined />,
      children: [
        {
          label: (<Link to='/customerlist'>Customer List</Link>),

        },
        // {
        //   label:(<Link to='/customermaintenance'>Customer</Link>),

        // },
        // {
        //   label:(<Link to='/paymentmaintenance'>Payment</Link>),

        // },
        // {
        //   label:(<Link to='/propertymaintenance'>Property</Link>),

        // },
      ],
    },
    
    {
      label: 'Projects',
      key: 'SubMenu4',
      icon: <FileOutlined />,
      children: [
        // {
        //   label: (<Link to='/estatelist'>All Agro Estates List</Link>),

        // },
        {
          label: (<Link to='/projectlist'>Projects List</Link>),

        },
        {
          label: (<Link to='/adminpropertylist'>Property List</Link>),

        },
        {
          label: (<Link to='/adminplotlist'>Plots List</Link>),

        },
        // {
        //   label: (<Link to='/purchasenewplot'>Purchase New Plot</Link>),

        // },
        // {
        //   label:(<Link to='/customermaintenance'>Customer</Link>),

        // },
        // {
        //   label:(<Link to='/paymentmaintenance'>Payment</Link>),

        // },
        // {
        //   label:(<Link to='/propertymaintenance'>Property</Link>),

        // },
      ],
    },
    {
      label: 'Payment',
      key: 'SubMenu3',
      icon: <PoundCircleOutlined />,
      children: [
        {
          label: (<Link to='/paymentmaintenancelist'>Payment List</Link>),

        },
        {
          label: (<Link to='/soldplots'>Commission Payments to the Marketing Leaders</Link>),

        },  
        // {
        //   label:(<Link to='/customermaintenance'>Customer</Link>),

        // },
        // {
        //   label:(<Link to='/paymentmaintenance'>Payment</Link>),

        // },
        // {
        //   label:(<Link to='/propertymaintenance'>Property</Link>),

        // },
      ],
    },

  ];
function AdminNav() {
    const onClick = (e) => {
        console.log('click ', e);
      };
      const [collapsed, setCollapsed] = useState(false);

      const toggleCollapsed = () => {
        setCollapsed(!collapsed);
      };
    return (
      <div className='flex-column nav'>
      {/* <Button type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
      {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button> */}
        <Menu 
        onClick={onClick}
      
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="horizontal"
        inlineCollapsed={collapsed}
        items={items}
      />
      </div>
    );
}

export default AdminNav;
