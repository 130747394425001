import React , {useEffect, useState}from 'react';
import { Table } from 'antd';
import AdminHeader from "../shared/header";
import CustomerNav from "../../shared/customernav";
import PaymentGrid from '../../shared/paymentgrid';
import { useSearchParams } from "react-router-dom";
function CustomerPayments() {
  const [searchParams, setSearchParams] = useSearchParams();  
  const [customerId, setCustomerId] = useState("");
  useEffect(() => {
    const customer_id = searchParams.get("cust_id");
    if (customer_id) {
      setCustomerId(customer_id);
    }
  }, []);
    const biscolumns = [
        {
          title: 'Location',
          dataIndex: 'location',
          key: 'location',
          render: (text) => <span>{text}</span>,
        },
        {
          title: 'project',
          dataIndex: 'project',
          key: 'project',
          render: (text) => <span>{text}</span>,
        },
    
    
        {
          title: 'Sale by',
          dataIndex: 'emp',
          key: 'project',
          render: (text) => <a>{text}</a>,
        },
       
        {
        title: 'Square Yards',
        dataIndex: 'squareyards',
        key: 'project',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'Price',
        dataIndex: 'Price',
        key: 'project',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'Payment Type',
        dataIndex: 'paytype',
        key: 'project',
        render: (text) => <span>{text}</span>,
      },
   
      ];
      const bisdata = [
        {
          key: '1',
          id: "1",
          emp: 'John',
          Price: '$600',
          paystatus:"Paid", 
          paytype:"EMI",
          project: "Project 1",
          location: "Hyderabd",
        },
    
      ];
    if(customerId != '') 
    return (
        <div className="admin ">
            <div className='flex flex-row fullheight'>

                <div className='admin-dashboard'>
                    <AdminHeader></AdminHeader>

                    <div className='flex-row cell'>

                        <div className='bodycontainer emp-view'>

                            <div className="flex flex-row fullheight ">
                                <div className="flex flex-row fullheight ">
                                    <CustomerNav></CustomerNav>

                                </div>
                                <div className="cell left-gutter-20">
                                    <div className="flex flex-column">
                                        <h1 className="cell">Payments</h1>
                                      <PaymentGrid  customerId={customerId} showButton={true}></PaymentGrid>
                                      
                                        {/* <Button type="secondary" className="right-gutter"></Button>
                                        <Button type="primary">Clear</Button> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CustomerPayments;
