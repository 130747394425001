import React, { useState, useEffect } from "react";

import { Button, Checkbox, Select, Form, Input, DatePicker, CollapseProps, UploadProps, Upload } from 'antd';
import { Collapse } from 'antd';
function AddPayment() {
    const items: CollapseProps[] = [
        {
            key: '1',
            label: 'Payment Details',
            children: <Paymentd />,
        },
        {
            key: '2',
            label: 'Plan A (OUT RATE UNITS)',
            children: <PlanA />,
        },
        {
            key: '3',
            label: 'Plan B (EASY INSTALLMENTS)',
            children: <PlanB />,
        },

    ];

    function Paymentd() {
        return (
            <>


                <Form.Item className='cell right-gutter'
                    label="Location"
                    name="firstname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your firstname!',
                        },
                    ]}
                >
                    <Select></Select>
                </Form.Item>
                <Form.Item className='cell'
                    label="Project"
                    name="lastname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your lastname!',
                        },
                    ]}
                >
                    <Select></Select>
                </Form.Item>

                <Form.Item
                    label="Total Cents"
                    name="location"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Gender!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Customer"
                    name="location"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Gender!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Customer Mobile"
                    name="location"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Gender!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Emp.ID"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Select></Select>
                </Form.Item>
                <Form.Item
                    label="Employee Name"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Select></Select>
                </Form.Item>
                <Form.Item
                    label="Plan"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Select></Select>
                </Form.Item>
                <Form.Item
                    label="Price"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Payment Method"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Select></Select>
                </Form.Item>
                <Form.Item
                    label="Payment Status"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Select></Select>
                </Form.Item>




            </>
        )
    }
    function PlanA() {
        return (
            <>

                <Form.Item
                    label="UNIT BOOKING
                    "
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="PLOT AGREEMENT (BEFORE 15 DAYS)"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="UNIT REGISTRATION (BEFORE 30 DAYS)"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Total Price"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

            </>
        )
    }
    function PlanB() {
        return (
            <>


                <Form.Item
                    label="UNIT BOOKING
                    "
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Monthly Price"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Select Number of Months"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Total Price of EMI"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="DURING REGISTRATION"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Total Price"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>


            </>
        )
    }

    return (


        <Form className="form" layout="vertical">
            <Collapse accordion items={items} defaultActiveKey={['1']} />
        </Form>

    );
}

export default AddPayment;