
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import logo from '../../assets/images/logoonly.png';
import logotitle from '../../assets/images/logotitle.png';
import banner from '../../assets/images/r-banner-1.jpeg';
import banner1 from '../../assets/images/r-banner-2.jpeg';
import banner2 from '../../assets/images/r-banner-3.jpeg';
import banner3 from '../../assets/images/r-banner-4.jpeg';
import secimg from '../../assets/images/r-img-2.jpeg';
import video from '../../assets/images/video.mp4'
import sandle from '../../assets/images/sandlewood.jpg';
import Hyd from "../../assets/images/hyd.png";
import Vij from "../../assets/images/vij.png";
import { Button, Checkbox, Select, Form, Input, DatePicker, CollapseProps, UploadProps, Upload, Radio } from 'antd';
import { getData, getEmployeeDropdownList, addData } from "../../services/services";
import { adminPlotsListAPI, employeesListAPI, addToCartAPI } from "../../common/constants";
import { Link } from "react-router-dom";
import PlotList from "../shared/plotlist";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
function home() {
    document.querySelector(`#home`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}
function scheme() {
    document.querySelector(`#schemes`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}
function aboutus() {
    document.querySelector(`#aboutus`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}
function benefits() {
    document.querySelector(`#benefits`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}
function sales() {
    document.querySelector(`#sales`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}
function terms() {
    document.querySelector(`#terms`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}
const AutoplaySlider = withAutoplay(AwesomeSlider);
function Home() {
    const [plots, setPlots] = useState([]);
    async function fetchPlots() {
        const res = await getData(adminPlotsListAPI);
        const response = await res.json();
        if (response.status === "success") {
            const listItems = response.data;
            setPlots(listItems);
        }
    }
    useEffect(() => {
        fetchPlots();
    }, []);

    return (

        <div className="home flex-column mainhome">

            <div className="container " id="home">
                <header className="flex-column">
                    <div className="content flex-row mob-flex-row">
                        <div className="logostyles">
                            <figure className="logo" ><img src={logo} /></figure>
                            <figure className="logotitle"><img src={logotitle} /></figure>
                        </div>

                        <div className="flex-row mob-flex-row vcenter">
                            <Link to="/employeelogin" className="right-gutter ant-btn-primary" >
                                <span class="material-symbols-outlined ">badge</span> <b>Agent Login</b>
                            </Link>
                            <Link to="/customerlogin" className="right-gutter ant-btn-secondary" >
                                <span class="material-symbols-outlined ">person</span><b>Customer Login</b>
                            </Link>
                        </div>
                    </div>
                </header>

                <div className="nav">
                    <div className="content flex-row middle">
                        <Link onClick={() => home()} >
                            <span class="material-symbols-outlined ">home</span> <b>Home</b>
                        </Link>
                        <Link onClick={() => aboutus()} >
                            <span class="material-symbols-outlined ">real_estate_agent</span> <b>About Us</b>
                        </Link>
                        <Link onClick={() => benefits()} >
                            <span class="material-symbols-outlined ">compost</span> <b>Avicado & Benefits</b>
                        </Link>
                        <Link onClick={() => sales()} >
                            <span class="material-symbols-outlined ">real_estate_agent</span> <b>Our Projects</b>
                        </Link>
                        <Link onClick={() => scheme()} >
                            <span class="material-symbols-outlined ">compost</span> <b>Scheme</b>
                        </Link>
                        <Link onClick={() => terms()} >
                            <span class="material-symbols-outlined ">description</span> <b>Terms & Conditions</b>
                        </Link>

                    </div>
                </div>
                <AutoplaySlider play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={6000}>
                    <div data-src={banner1} ><div className="overlay"></div><div className="banner"> <h6><strong>₹ 6,00,000 /- <small>10 Cents, 484 Sq. Yds</small></strong>
                        <h1 className="years">25 years of continous income</h1>
                        <h1 className="years years-right"><div>Easy Installments
                            <ul>
                                <li>Pass Books</li>
                                <li>Spot Registration</li>
                                <li>Formar Assurance</li>
                            </ul>
                        </div> </h1>
                    </h6></div></div>
                    <div data-src={banner2}><div className="overlay"></div>2</div>
                    <div data-src={banner3}><div className="overlay"></div>3</div>
                </AutoplaySlider>
                <section className="branches bottom-pad-50 top-pad-100 " id="sales">
                    <div className="content flex-column">
                        <h3>Branches</h3>
                        <div className="flex-row space-evenly">
                            <Link onClick={() => sales()}>
                                <figure>
                                    <img src={Hyd} />
                                </figure>
                                <figcaption>Hyderabad</figcaption>

                            </Link>
                            <Link onClick={() => sales()}>
                                <figure>
                                    <img src={Vij} />
                                </figure>
                                <figcaption>Vijayawada</figcaption>

                            </Link>
                            <Link onClick={() => sales()}>
                                <figure>
                                    <img src={Hyd} />
                                </figure>
                                <figcaption>Guntur</figcaption>

                            </Link>
                            <Link onClick={() => sales()}>
                                <figure>
                                    <img src={Vij} />
                                </figure>
                                <figcaption>Nandyala</figcaption>

                            </Link>
                        </div>
                    </div>
                </section>
                <section className="salespan bottom-pad-50 " id="sales">
                    <div className="content">
                        <div className="emp-dashboard ">
                            <div className="propertylist ">
                                <PlotList plots={plots} isAdmin={false}></PlotList>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="banner bottom-pad-50 banbg top-pad-100" id="aboutus">

                    <div className="content flex-column vcenter">

                        <div className="sectioncontent">
                            <div className=" flex-row mob-flex-row vcenter">
                                <figure className="cell">
                                    <video width="100%" controls autoPlay>
                                        <source src={video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </figure>

                                <div className="flex-column cell">
                                    <h3>The Idea is <b>yours</b> the practis is <b>ours</b></h3>
                                    <p>We lived in a constantly polluted environment. Everyone's desire is to rest in the lap of nature. But land prices are not available for own farming. So, Mea Maa <b>Shree Lakshmi Agro Farms & Projects </b>is giving you an opportunity to do agriculture along with the land Avocado is native to Mexico. </p>
                                    <p>Avocado is a native to Mexico. Avocado is also knows as butter fruit or alligator pear. It also appears an almost every restaurant menu. This avocado, apart from being a health-rich fruit, can also be added to a variety of foods like salads, smoothies, donuts and sandwiches. It is a fruit that is not widely known but has many health benefits. </p>
                                    <p>It is slightly bitter in tast. Has a slight buttery taste. Available in all supermarkets. Avocado fruit plants and red sandalwood plants - way to turn traders into millionares and earn in a short period of time.</p>

                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="purchasescheme " id="schemes">
                    <div className="content">
                        <h3>Sri Lakshmi Agro Farm Scheme Plans</h3>
                        <Form className="form flex-row" layout="vertical">
                            <div className="flex-column right-gutter cell">
                                <h1>Plan A</h1>
                                <h2>Out Rate Units for  for <br /><b>10 Cents (484 Square Yards)</b></h2>
                                <div className="flex flex-column plan1">

                                    <Form.Item className='cell'
                                        label="Unit Booking"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="50,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="Plot Agreement (before 15 days)"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="1,00,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="Unit Registration (before 30 days)"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="4,50,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="Total"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="6,00,000" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="flex-column left-gutter cell">
                                <h1>Plan B</h1>
                                <h2>Easy Installments  for <br /><b>10 Cents (484 Square Yards)</b></h2>
                                <div className="flex flex-column plan1">
                                    <Form.Item className='cell'
                                        label="Unit Booking"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="10,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="Every Month (10,000 x 59 months)"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="5,90,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="During Registration (60th Month)"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="1,50,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="Total"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="7,50,000" />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                </section>
                <section className="banner bottom-pad-50 banbg top-pad-100" id="benefits" >

                    <div className="content flex-column vcenter">

                        <div className="sectioncontent">
                            <div className=" flex-row mob-flex-row vcenter">


                                <div className="flex-column cell">
                                    <h3>Health Benefits</h3>
                                    <p>Avocado is a native to Mexico. Avocado is also knows as butter fruit or alligator pear. It also appears an almost every restaurant menu. This avocado, apart from being a health-rich fruit, can also be added to a variety of foods like salads, smoothies, donuts and sandwiches. It is a fruit that is not widely known but has many health benefits.</p>
                                    <ul>
                                        <li>Helps with weight loss.</li>
                                        <li>Lower cholesterol.</li>
                                        <li>Regulates sugar levels in the blood</li>
                                        <li>Can reduce eye disorders like cataracts, and eye spots.</li>
                                        <li>Has the ability to inhabit the growth of prostate cancer cells</li>
                                        <li>Promotes fertility</li>
                                        <li>Helps in reducing mental disorders like depression and anxiety.</li>
                                        <li>Relieves arthritis symptoms</li>
                                        <li>Checks digestive problems</li>
                                        <li>Also prevents stretch marks on the skin</li>
                                        <li>It also has the property of controlling hypertension.</li>
                                    </ul>
                                </div>
                                <figure className="cell vcenter flex middle">
                                    <img src={secimg} width={"50%"} />
                                </figure>
                            </div>
                        </div>
                    </div>

                </section>


                <section className="sectioncontent terms" id="terms">
                    <div className="content">
                        <h3>terms & Conditions</h3>
                        <div className=" flex-row mob-flex-row">
                            <figure>
                                <img src={sandle} />
                            </figure>
                            <ul>
                                <li>One Unit is 10 cents / 484 Square yards / 4356 sare feet, 20 avocado plants and 10 red sandanlwood are given in one unit.</li>
                                <li>The amount is non-refundable for those who opt out of an installment plan. And for those who drop out in two installments your money will be given after 60 days.</li>
                                <li>Those who bought in unit out rate through Avocado will get income from 5th year. And those who bought in easy installments will be given income in the 6the year through Avacado trees. </li>
                                <li>50% of the revenue generated goes to our valued customer and 50% goes to the company for the maintenance</li>
                                <li>Applies you have to bear the taxes as per the government rules on the income received by you.</li>
                                <li>A crop MOU (Memorandum of Underbonding) will be entered into between the city Unit holder and the company for 10 years (Renewal agreement will be given every 10 years).</li>
                                <li>Customer who take up the unit will be given free Club House Lifetime membership at the rate of 5 days per year. (This provision is applicable aftr 4 years)</li>
                                <li>Your unit can be tranferred any where in upcoming projects of <b>Sri Lakshmi Agro Farms & Projects.</b></li>
                                <li>You can also sell or tranfer to others</li>
                                <li>One unit i.e. 10 cents farmer's relative and passbook will be given as per Govt.</li>
                                <li>Attractive draw functions are performed</li>
                                <li>24/7 security, entrance arse, entrance compound wall, whole site diamond fencing is provided. </li>
                                <li>The resort facilities include smimming pool, children's play area, cottages, convention hall, club house and many more facilities</li>
                                <li>Aggreement with plot number will be given to those who have paid 25%</li>
                                <li>You have to bear the unit registration charges as per Govt.</li>
                                <li>Our unit is provided with wide 40 feet and 30 feet roads</li>
                                <li>130 feet roads and 40 feed roads corner bits will have to pay an additional Rs. 200/-</li>


                            </ul>
                        </div>
                    </div>
                </section>

                <footer>
                    © 2024 <b>SRI LAKSHMI AGRO FARMS</b>. ALL RIGHTS RESERVED
                </footer>
            </div>

        </div>


    );
}

export default Home;
