import React, { useState, useEffect } from "react";
import { Space, Table, Tag, Modal, Popconfirm, Button } from "antd";
import logo from "../../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import AdminHeader from "../shared/header";
import AdminFooter from "../shared/footer";
import AdminNav from "../shared/nav";
import EditEmployee from "./editemployee";
import { getData, deleteData } from "../../../services/services";
import {
  adminEmployeesListAPI,
  deleteEmployyeAPI,
} from "../../../common/constants";

function EmployeeList() {
  const [employees, setEmployees] = useState([]);

  async function fetchEmployeesList() {
    const res = await getData(adminEmployeesListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setEmployees(listItems);
    }
  }

  async function deleteEmployee(emp_id) {
    const res = await deleteData(deleteEmployyeAPI, { emp_id: emp_id });
    const response = await res.json();
    if (response.status === "success") {
      fetchEmployeesList();
    } else {
      alert("Error in Deleting employee");
    }
  }

  useEffect(() => {
    fetchEmployeesList();
  }, []);
  const columns = [
    {
      title: "Agent ID",
      dataIndex: "emp_id",
      key: "emp_id",
      // render: (text) => <span>{text}</span>,
    },
    {
      title: "introducer ID",
      dataIndex: "reference",
    },
    {
      title: "First Name",
      dataIndex: "name",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "DOB ",
      dataIndex: "dateofbirth",
      key: "dateofbirth",
    },
    {
      title: "Email ",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Designation ",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "mobile ",
      dataIndex: "mobile_number",
    },
    {
      title: "Work Location ",
      dataIndex: "location",
    },

    {
      title: "Status",
      dataIndex: "delete_status",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record: { title: React.Key }) =>
        employees.length >= 1 ? (
          <Space size="middle">
            <span
              className="material-symbols-outlined gridbtn"
              onClick={() => handleEdit(record)}
            >
              Edit{" "}
            </span>
            {/* <span
              className="material-symbols-outlined gridbtn"
              onClick={() => handleDelete(record)}
            >
              toggle_on
            </span> */}
          </Space>
        ) : null,
    },
  ];

  const handleDelete = (item) => {
    deleteEmployee(item.emp_id);
  };
  const handleEdit = (item) => {
    const empID = item.emp_id;
    const emp_id = empID.replace("/", "_");
    window.location.replace("/teamview?emp_id=" + emp_id);
    return;
  };

  return (
    <div className="admin ">
      <div className="flex flex-row fullheight">
        <div className="admin-dashboard">
          <AdminHeader></AdminHeader>

          <div className="flex-row cell">
            <div className="bodycontainer">
              <div className="flex flex-row vcenter bottom-gutter-20">
                <h1 className="cell">All Agents</h1>
                <a href="\teamview" className="ant-btn-primary">
                  Add Agent
                </a>
              </div>
              <Table
                columns={columns}
                dataSource={employees}
                pagination={false}
                scroll={{ y: 400 }}
                className="tablescroll1"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Modal title="Edit Employee Details" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <EditEmployee></EditEmployee>
            </Modal> */}
    </div>
  );
}

export default EmployeeList;
