import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Tag,
  Modal,
  Button,
  Checkbox,
  Form,
  Input,
  Spin,
  Select,
} from "antd";
import logo from "../../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import AdminHeader from "../shared/header";
import AdminFooter from "../shared/footer";
import AdminNav from "../shared/nav";
import PurchaseScheme from "../../shared/purchasescheme";
import PlotList from "../../shared/plotlist";
// import EditEmployee from '../modal/editemployee';
import { getData, deleteData } from "../../../services/services";
import { adminPlotsListAPI, deleteProjectAPI } from "../../../common/constants";
import PlotRegistration from "../../shared/plotregistration";

function PurchaseWizard() {

  return (
    <div className="admin ">
      <div className="flex flex-row fullheight">
        <div className="admin-dashboard">
          <AdminHeader></AdminHeader>

          <div className="flex-row cell">
            <div className="bodycontainer">
              <div className="flex-column top">
                <div className="emp-dashboard flex flex-column cell nobgform fullwidth">
                    <PlotRegistration></PlotRegistration>               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseWizard;
