
import React, { useState } from 'react';

import CustomerHeader from '../shared/header';

import PlotRegistration from '../../shared/plotregistration';

function CustomerViewPlotRegistration() {


  
    return (
        <div className='customer'>
            <div className="customerdashboard flex flex-row cell">
                <CustomerHeader></CustomerHeader>
                <div className='customerbody '>
                   <PlotRegistration></PlotRegistration>
                </div>
            </div>
            
        </div>
    );
}

export default CustomerViewPlotRegistration;
