import React, { useState, useEffect } from "react";

import { Button, Checkbox, Select, Form, Input, DatePicker, CollapseProps, UploadProps, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import AdminHeader from "../shared/header";

import { Collapse } from 'antd';
function AddProperty() {
    const items: CollapseProps[] = [
        {
            key: '1',
            label: 'Property Details',
            children: <Location />,
        },
        {
            key: '2',
            label: 'Plan A (OUT RATE UNITS)',
            children: <PlanA />,
        },
        {
            key: '3',
            label: 'Plan B (EASY INSTALLMENTS)',
            children: <PlanB />,
        },

    ];

    function Location() {
        return (
            <>


                <Form.Item className='cell right-gutter'
                    label="Location"
                    name="firstname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your firstname!',
                        },
                    ]}
                >
                   <Input />
                </Form.Item>
                <Form.Item className='cell'
                    label="Project"
                    name="lastname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your lastname!',
                        },
                    ]}
                >
                   <Input />
                </Form.Item>
                <Form.Item className='cell'
                    label="Property Name"
                    name="lastname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your lastname!',
                        },
                    ]}
                >
                   <Input />
                </Form.Item>
                <Form.Item
                    label="Price in Cent"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Price In Square Yards"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Total Cents"
                    name="location"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Gender!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Total Price"
                    name="location"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Gender!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                
                <Form.Item
                    label="Description"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Select></Select>
                </Form.Item>
             
                <Form.Item
                    label="Property Image"
                    name="profile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your profile pic!',
                        },
                    ]}
                >
                    <Upload
                        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                        listType="picture"

                    >
                        <Button icon={<UploadOutlined />}>Upload Profile Picture</Button>
                    </Upload>
                </Form.Item>


            </>
        )
    }
    function PlanA() {
        return (
            <>

                <Form.Item
                    label="UNIT BOOKING
                    "
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="PLOT AGREEMENT (BEFORE 15 DAYS)"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="UNIT REGISTRATION (BEFORE 30 DAYS)"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Total Price"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

            </>
        )
    }
    function PlanB() {
        return (
            <>

               
<Form.Item
                    label="UNIT BOOKING
                    "
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Monthly Price"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Select Number of Months"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Total Price of EMI"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="DURING REGISTRATION"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Total Price"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your mobile!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>


            </>
        )
    }

    return (

        <div className="admin ">
            <div className='flex flex-row fullheight'>

                <div className='admin-dashboard'>
                    <AdminHeader></AdminHeader>


                    <div className='bodycontainer emp-view'>
                        <div className="flex flex-column  accordion bottom-gutter-20">
                            <h1 className="cell">Add Property</h1>
                            <Form className="form" layout="vertical">
                                <Collapse accordion items={items} defaultActiveKey={['1']} />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddProperty;