import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
  getDataWithPayload,
  getData,
  formatPrice,
} from "../../services/services";
import { cartDetailsBasedOnIDAPI } from "../../common/constants";

function PlotRegistration() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [custId, setCustId] = useState("");
  const [data, setData] = useState([]);

  async function fetchCartDetails(id) {
    const res = await getDataWithPayload(cartDetailsBasedOnIDAPI, {
      customer_id: id,
    });
    // const res = await getData(getCartDetailsAPI);
    const response = await res.json();
    if (response.status === "success") {
      setData(response.data);
      console.log(response.data);
    }
  }
  useEffect(() => {
    localStorage.setItem("cartItem", "{}");
    const cust_id = searchParams.get("cust_id");
    setCustId(cust_id);
    if (cust_id);
    fetchCartDetails(cust_id);
  }, []);
  const CartItems = () => {
    const Items = data.map((Item) => (
      <div className="purchaseditems">
        <div className="flex flex-row flex-start">
          <article className="customerdet">
            <div className="headsec flex-row">
              <dl>
                <dt>Project</dt>
                <dd>{Item.project_name}</dd>
              </dl>
              <dl>
                <dt>Property</dt>
                <dd>{Item.property_name}</dd>
              </dl>
            </div>
            <dl>
              <dt>Customer Name</dt>
              <dd>{Item.customerName}</dd>
            </dl>
            <dl>
              <dt>Plot #</dt>
              <dd>{Item.plot_number}</dd>
            </dl>

            <dl>
              <dt>Sq. Yds.</dt>
              <dd>{Item.square_yards}</dd>
            </dl>
            <dl>
              <dt>Plan</dt>
              <dd>{Item.plan_type == 'Other' ? 'Book a new unit' : Item.plan_type}</dd>
            </dl>
          </article>
          <div className="flex-column">
            <aside className="pricesec">
              <h2>Order Summary</h2>
              {Item.plan_type != "Other" && (
                <div>
                  <dl>
                    <dt>Unit Booking</dt>
                    <dd>
                      {Item.plan_type == "planA"
                        ? formatPrice(Item.booking_amount)
                        : formatPrice(Item.emi_booking_amount)}
                    </dd>
                  </dl>
                  {Item.plan_type == "planA" ? (
                    <dl>
                      <dt>PLOT AGREEMENT Price</dt>
                      <dd>{formatPrice(Item.agreement_amount)}</dd>
                    </dl>
                  ) : (
                    <div>
                      <dl>
                        <dt>PLOT EMI Price</dt>
                        <dd>{formatPrice(Item.emi_amount)}</dd>
                      </dl>
                      <dl>
                        <dt>
                          Total EMI Price({Item.emi_amount} X{" "}
                          {Item.number_of_emis} Months)
                        </dt>
                        <dd>
                          {formatPrice(
                            Number(Item.emi_amount) *
                              Number(Item.number_of_emis)
                          )}
                        </dd>
                      </dl>
                    </div>
                  )}
                  <dl>
                    <dt>UNIT REGISTRATION Price</dt>
                    <dd>
                      {Item.plan_type == "planA"
                        ? formatPrice(Item.registration_amount)
                        : formatPrice(Item.emi_registration_amount)}
                    </dd>
                  </dl>
                </div>
              )}
              <dl className="totalprice">
                <dt>Total</dt>
                <dd>
                  {Item.plan_type == "planA"
                    ? formatPrice(Item.total_amount)
                    : formatPrice(Number(Item.total_amount))}
                </dd>
              </dl>
            </aside>
            <Link
              className="ant-btn-primary top-gutter-20 bottom-gutter-20 right-gutter left-gutter self-end"
              onClick={() => handleProceed(Item)}
            >
              Proceed to Pay
            </Link>
          </div>
        </div>
      </div>
    ));
    return <div className="flex-column mob-flex-row">{Items}</div>;
  };
  const handleProceed = (Item) => {
    localStorage.setItem("cartItem", JSON.stringify(Item));
    window.location.replace("/paymentview");
    return;
  };
  if (data.length > 0)
    return (
      <div className="customerdashboard flex flex-row cell fullwidth">
        <div className="customerbody fullwidth">
          <section className="top-gutter-40">
            <div className="pricedetails">
              <h2>Purchased Items</h2>
              <CartItems />
            </div>
            {/* <Steps current={current} items={items} />
                    <div style={contentStyle} className='scroll'>{steps[current].content}</div>

                    <div className='flex end top-gutter-20'>
                        {current > 0 && (
                            <Button className='right-gutter'
                                type='secondary'
                                onClick={() => prev()}
                            >
                                Previous
                            </Button>
                        )}
                        {current < steps.length - 1 && (
                            <Button type="primary" onClick={() => next()}>
                                Next
                            </Button>
                        )}

                    </div> */}
          </section>
        </div>
      </div>
    );
  else return "loading....!";
}

export default PlotRegistration;
