import React, { useState, useEffect } from "react";

import { Button, Checkbox, Select, Form, Input, DatePicker, CollapseProps, UploadProps, Upload, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';


import { Collapse } from 'antd';
function PurchaseScheme() {

    return (
        <div className="purchasescheme">
            <Form className="form" layout="horizontal">
                <Radio.Group >
                    <Radio value={1} disabled>   <h1>Plan A</h1>
                        <h2>Out Rate Units for  for <b>10 Cents (484 Square Yards)</b></h2>
                        <div className="flex flex-column plan1">

                            <Form.Item className='cell'
                                label="Unit Booking"
                                name="unitbooking"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your firstname!',
                                    },
                                ]}
                            >
                                <Input disabled defaultValue="₹ 50,000" />
                            </Form.Item>
                            <Form.Item className='cell'
                                label="Plot Agreement (before 15 days)"
                                name="unitbooking"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your firstname!',
                                    },
                                ]}
                            >
                                <Input disabled defaultValue="₹ 1,00,000" />
                            </Form.Item>
                            <Form.Item className='cell'
                                label="Unit Registration (before 30 days)"
                                name="unitbooking"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your firstname!',
                                    },
                                ]}
                            >
                                <Input disabled defaultValue="₹ 4,50,000" />
                            </Form.Item>
                            <Form.Item className='cell'
                                label="Total"
                                name="unitbooking"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your firstname!',
                                    },
                                ]}
                            >
                                <Input disabled defaultValue="₹ 6,00,000" />
                            </Form.Item>
                        </div>
                    </Radio>
                    <Radio value={2} disabled>
                        <h1>Plan B</h1>
                        <h2>Easy Installments  for <b>10 Cents (484 Square Yards)</b></h2>
                        <div className="flex flex-column plan1">
                            <Form.Item className='cell'
                                label="Unit Booking"
                                name="unitbooking"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your firstname!',
                                    },
                                ]}
                            >
                                <Input disabled defaultValue="₹ 10,000" />
                            </Form.Item>
                            <Form.Item className='cell'
                                label="Every Month (10,000 x 59 months)"
                                name="unitbooking"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your firstname!',
                                    },
                                ]}
                            >
                                <Input disabled defaultValue="₹ 5,90,000" />
                            </Form.Item>
                            <Form.Item className='cell'
                                label="During Registration (60th Month)"
                                name="unitbooking"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your firstname!',
                                    },
                                ]}
                            >
                                <Input disabled defaultValue="₹ 1,50,000" />
                            </Form.Item>
                            <Form.Item className='cell'
                                label="Total"
                                name="unitbooking"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your firstname!',
                                    },
                                ]}
                            >
                                <Input disabled defaultValue="₹ 7,50,000" />
                            </Form.Item>
                        </div>
                    </Radio>

                </Radio.Group>
            </Form>
        </div>
    );
}

export default PurchaseScheme;