
import React, { useState } from 'react';
import { Space, Table, Tag, Modal } from 'antd';
import logo from '../../../assets/images/Logo.png';
import { Link } from "react-router-dom";
import AdminHeader from '../shared/header';
import AdminFooter from '../shared/footer';
import AdminNav from '../shared/nav';
import PaymentGrid from '../../shared/paymentgrid';


function PaymentMaintenanceList() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="admin ">
      <div className='admin-dashboard'>
        <AdminHeader></AdminHeader>
        <div className='flex-row cell'>

          <div className='bodycontainer'>
            <div className="flex flex-row vcenter bottom-gutter-20">
              <h1 className="cell">All Payments</h1>

              {/* <Link to="/adminaddpayment" className="ant-btn-primary">Add Payment</Link> */}
            </div>
            <PaymentGrid showButton={true}></PaymentGrid>
          </div>
        </div>
      </div>

    </div>

  );
}

export default PaymentMaintenanceList;
