import React from 'react';
import { Table } from 'antd';
import AdminHeader from "../shared/header";
import CustomerNav from "../../shared/customernav";
import CustomerPropertyList from '../../shared/customerpropertylist';
function CustomerPurchased() {
    const biscolumns = [
        {
          title: 'Location',
          dataIndex: 'location',
          key: 'location',
          render: (text) => <span>{text}</span>,
        },
        {
          title: 'project',
          dataIndex: 'project',
          key: 'project',
          render: (text) => <span>{text}</span>,
        },
    
    
        {
          title: 'Sale by',
          dataIndex: 'emp',
          key: 'project',
          render: (text) => <a>{text}</a>,
        },
       
        {
        title: 'Square Yards',
        dataIndex: 'squareyards',
        key: 'project',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'Price',
        dataIndex: 'Price',
        key: 'project',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'Payment Status',
        dataIndex: 'paystatus',
        key: 'project',
        render: (text) => <span>{text}</span>,
      },

      ];
      const bisdata = [
        {
          key: '1',
          id: "1",
          emp: 'John',
          Price: '$600',
          paystatus:"Paid", 

          project: "Project 1",
          location: "Hyderabd",
        },
    
      ];
    return (
        <div className="admin ">
            <div className='flex flex-row fullheight'>

                <div className='admin-dashboard'>
                    <AdminHeader></AdminHeader>

                    <div className='flex-row cell'>

                        <div className='bodycontainer emp-view'>

                            <div className="flex flex-row fullheight ">
                                <div className="flex flex-row fullheight ">
                                    <CustomerNav></CustomerNav>

                                </div>
                                <div className="cell left-gutter-20">
                                    <div className="flex flex-column">
                                        <h1 className="cell">Purchased Properties</h1>
                                     <CustomerPropertyList></CustomerPropertyList>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CustomerPurchased;
