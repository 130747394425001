
import React from 'react';
import { Button, Checkbox, Select, Form, Input,DatePicker } from 'antd';
import EmployeeHeader from '../shared/header';
import EmployeeNav from '../shared/nav';
import { Link } from "react-router-dom";
import AddPayment from '../../shared/addpaymentdetails';
function AddEditPaymentHistory() {

    return (
 
<div className="employee emp-dashboard flex flex-column cell">
            <EmployeeHeader></EmployeeHeader>
            <div className='flex-row cell'>
                <EmployeeNav></EmployeeNav>
                <div className='bodycontainer'>
                    <h2>Payment</h2>

                    <div className='flex-column top top-gutter-20 fullwidth'>
                    <Link to="/addeditthistory"  className="ant-btn-primary bottom-gutter-20"  >Add Payment</Link>
                    <div className='admin fullwidth'>
                        <div className='admin-dashboard'>
                            <div className='emp-view'>
                                <div className='accordion '>
                   <AddPayment></AddPayment>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>

                </div>
            </div>
            {/* <Modal title="Add/Edit Payments" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
               <AddEditPayment></AddEditPayment>
            </Modal> */}
        </div>

    );
}

export default AddEditPaymentHistory;
