
import React, { useState } from 'react';
import { Space, Table, Tag, Modal } from 'antd';
import logo from '../../../assets/images/Logo.png';
import { Link } from "react-router-dom";
import AdminHeader from '../shared/header';
import AdminFooter from '../shared/footer';
import AdminNav from '../shared/nav';
import AddPayment from '../../shared/addpaymentdetails';



function AddAdminPay() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="admin ">
      <div className='admin-dashboard'>
        <AdminHeader></AdminHeader>
        <div className='flex-row cell emp-view'>

          <div className='bodycontainer accordion'>
            <div className="flex flex-column bottom-gutter-20">
              <h1 className="cell">Add Payment</h1>
                <AddPayment></AddPayment>
             
            </div>

          </div>
        </div>
      </div>

    </div>

  );
}

export default AddAdminPay;
