import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Select,
  Form,
  Input,
  DatePicker,
  message,
  Steps,
  theme,
  Upload,
} from "antd";
import AdminHeader from "../shared/header";
import EmployeeNav from "../shared/nav";
import { UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SharedEditEmployer from "../../shared/addeditemployer";
function EmpProfile() {
  const [empID, setEmpID] = useState("");
  const [name, setName] = useState("");
  const [profilePic, setProfilePic] = useState("");
  useEffect(() => {
    const empData = localStorage.getItem("userDetails");
    const data = JSON.parse(empData);
    setEmpID(data.emp_id);
    setName(data.name);
    setProfilePic(data.profile_pic);
  });

  return (
    <div className="employee emp-dashboard flex flex-column cell">
      <AdminHeader></AdminHeader>
      <div className="flex-row cell">
        <EmployeeNav></EmployeeNav>
        <div className="bodycontainer nobgform ">
          <h2>Update Profile</h2>
          <article className="totalproperties cell blackbg">
            <figure className="cell">
            <img src={'https://srilakshmiagrofarms.co.in/webapplication/uploads/employees/'+profilePic} alt="" />
              <figcaption>
                {name} 
              </figcaption>
            </figure>
            {/* <div className='flex end'>
                            <Link to='/addteammember' className='iconbutton'><span class="material-symbols-outlined size-32">
                                edit
                            </span></Link>
                        </div> */}
          </article>
          <div className="admin">
            <div className="admin-dashboard">
              <div className="emp-view">
                <div className="accordion ">
                  <SharedEditEmployer empID={empID}></SharedEditEmployer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmpProfile;
