
import React, { useState } from 'react';
import { Button, Checkbox, Select, Form, Input, DatePicker, message, CollapseProps, Steps, theme } from 'antd';
import { Collapse } from "antd";
import logo from '../../../assets/images/logoonly.png';
import logotitle from '../../../assets/images/logotitle.png';
import AddEditCustomer from '../../shared/addeditcustomer';
import { Link } from "react-router-dom";
import PurchaseScheme from "../../shared/purchasescheme";
function CustomerRegistration() {


    return (
        <div className='customer customerloginform'>
            <div className="customerlogin flex flex-row cell">
            <div className="flex flex-row boxshadow">
                <div class="customerlogdet">
                    <div className="logostyles">
                        <figure className="logo">
                            <img src={logo} />
                        </figure>
                        <figure className="logotitle">
                            <img src={logotitle} />
                        </figure>
                    </div>
                    <PurchaseScheme></PurchaseScheme>
                </div>

                <div className="customerregistration form-cont flex-column">

                    <AddEditCustomer></AddEditCustomer>
                    <span className="cell loginacc">
                        If you are having account, {' '}
                        <Link to="/customerlogin">Login</Link>
                    </span>
                </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerRegistration;
