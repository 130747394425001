import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Select,
  Form,
  Input,
  DatePicker,
  Spin,
  message,
  Upload
} from "antd";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logoonly.png";
import logotitle from "../../../assets/images/logotitle.png";
import EmployeeHeader from "../../employee/shared/header";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import loginimg from '../../../assets/images/loginagent.png';
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  addData,
  getData,
  getEmployeeDropdownList,
  addOrupdateDataWithImage,
} from "../../../services/services";
import {
  locationAPI,
  registerAPI,
  states,
  employeesListAPI,
  errorMessage,
} from "../../../common/constants";

function EmployeeRegistration() {
  const [hasError, setHasError] = useState(false);
  const [apiError, setApiError] = useState(errorMessage);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [referrenceList, setReferrenceList] = useState([]);
  const [file, setFile] = useState(null);

  const handleUpload = (info) => {    
    if (info.file.status === "done") {
      const fileObj = info.file.originFileObj;
      setFile(fileObj);
    }
  };

  useEffect(() => {
    async function fetchLocations() {
      const res = await getData(locationAPI);
      const response = await res.json();
      setLocations(response.locations);
    }
    async function fetchEmployeesList() {
      const res = await getData(employeesListAPI);
      const response = await res.json();
      if (response.status === "success") {
        const listItems = response.data;
        const list = getEmployeeDropdownList(listItems);
        setReferrenceList(list);
      }
    }
    fetchLocations();
    fetchEmployeesList();
  }, []);

  //Form submit method
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      setHasError(false);
   
      const formData = new FormData();
      const dateofbirth = dayjs(values.dateofbirth, "YYYY-MM-DD");
      formData.append("name", values.name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("dateofbirth", dateofbirth);
      formData.append("address1", values.address1);
      formData.append("address2", values.address2);
      formData.append("city", values.city);
      formData.append("location", values.location);
      formData.append("mobile_number", values.mobile_number);
      formData.append("reference", values.reference);
      formData.append("state", values.state);
      if (file) {
        formData.append("profile_pic", file);
      }

      const response = await addOrupdateDataWithImage(registerAPI, formData);
      const result = await response.json();
      if (result && result.status === "success") {
        setIsLoading(false);
        //alert('Please check your email for username and password');
        message.success("Please check your email for username and password");

        window.location.replace("/employeelogin");
      } else {
        setHasError(true);
        setIsLoading(false);
        setApiError(result.status);
      }
    } catch {
      setHasError(true);
      setIsLoading(false);
      console.log("Catch block register api error!");
    }
  };

  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };
  return (
    <div className="employee emplogin">
      <div className="customerlogin flex cell mob-flex-row">
      <div className="logindetails cell">
        <div className="logostyles">
          <figure className="logo" ><img src={logo} /></figure>
          <figure className="logotitle"><img src={logotitle} /></figure>
        </div>
        <h1>Welcome to Agent Application</h1>
        <img src={loginimg} />
        </div>
        <div className="flex flex-column cell scroll vcenter">
        <Form
          className="form"
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleErrors}
        >

          <h2 className="flex">
          <span class="material-symbols-outlined size-50">
              account_circle
            </span>
            <span className="text-center">Registration</span>
          </h2>
          <div >
            <div className="flex-row">
              <Form.Item
                className="cell right-gutter"
                label="First Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your firstname!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="cell"
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your lastname!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item
              label="Date of Birth"
              name="dateofbirth"
              rules={[
                {
                  required: true,
                  message: "Please input your DOB!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mobile"
              name="mobile_number"
              rules={[
                {
                  required: true,
                  message: "Please input your mobile!",
                },
                {
                  pattern: /^[\d]{10,10}$/,
                  message: "Mobile should be 10 digits",
                }
              ]}
            >
              <Input />
            </Form.Item> 
             <Form.Item
              label="Working Location"
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please input your Location!",
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                options={locations}
              />
            </Form.Item>
            <Form.Item
              label="Introducer ID"
              name="reference"
              rules={[
                {
                  required: true,
                  message: "Please input your Introducer id!",
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                options={referrenceList}
              />
            </Form.Item>
            <Form.Item
              label="Address 1"
              name="address1"
              rules={[
                {
                  required: true,
                  message: "Please input your address 1!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Address 2"
              name="address2"
             
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please input your City!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please input your St!",
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                options={states}
              />
            </Form.Item> 
            <Form.Item label="Profile Picture" name="profile_pic">
                <Upload
                  onChange={handleUpload}
                  action="https://srilakshmiagrofarms.co.in/webapplication/api/register-data"
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>Upload Profile Picture</Button>
                </Upload>
              </Form.Item>
          </div>
          <div className="flex-column">
            <Form.Item
              name="remember"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: "Please input your mobile!",
                },
              ]}
            >
              <Checkbox>I agree to the Terms & Privacy Policy</Checkbox>
            </Form.Item>
            <span className="cell text-center bottom-gutter">
              If you are not having account,{" "}
              <Link to="/employeelogin">Login</Link>
            </span>
          </div>
          {hasError && <ErrorComponent errorMessage={apiError} />}
          {isLoading && <Spin />}
          <Form.Item className="middle flex">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        </div>
      </div>
    </div>
  );
}

export default EmployeeRegistration;
