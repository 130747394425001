import React from "react";


import { Button, Form, Input, CollapseProps, Spin, Select, DatePicker } from "antd";
import AdminHeader from "../admin/shared/header";


import { Collapse } from "antd";
function PayDueAmountModal() {
  


  const items: CollapseProps[] = [
    {
      key: "1",
      label: "Due Payment",
      children: <Duepay />,
    },
  ];

  const statusList = [
    { value: "active", label: <span>ACTIVE</span> },
    { value: "inactive", label: <span>INACTIVE</span> },
  ];

  function Duepay() {
    return (
      <>
        <Form.Item
          className="cell"
          label="pay date"
          name="project_name"
          rules={[
            {
              required: true,
              message: "Please input your project name!",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        
        <Form.Item
          label="Due Amout"
          name="status"
          rules={[
            {
              required: true,
              message: "Please input your status!",
            },
          ]}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Pay Amount"
          name="description"
          
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Pending Amount"
          name="description"
          
        >
          <Input disabled={true} />
        </Form.Item>
      </>
    );
  }


    return (
      <div className="admin ">
        <div className="flex flex-row fullheight">
          <div className="admin-dashboard">
            <AdminHeader></AdminHeader>

            <div className="bodycontainer emp-view">
              <div className="flex flex-column  accordion bottom-gutter-20">
                <Form
                  className="form"
                  layout="vertical"
                 
                  
                >
                  <div className="flex flex-row vcenter around-gutter">
                    <h1 className="flex-row cell no-gutter">Pay Due Amount </h1>
                  
                    <Button type="primary" htmlType="submit" className="right-gutter">
                      Pay
                    </Button>
                    <Button type="primary" >
                      Cancel
                    </Button>
                  </div>
                  <Collapse accordion items={items} defaultActiveKey={["1"]} />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default PayDueAmountModal;
