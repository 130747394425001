import React, { useState, useEffect } from "react";
import { Space, Table, Button, Form, Select, DatePicker, Modal } from "antd";

import {
  getData,
  getProjectDropdownList,
  getPropertiesDropdownList,
  getPlotDropdownList,
  formatPrice,
  updateData,
} from "../../services/services";
import {
  getCommissionDataAPI,
  updateComissionPayAPI,
} from "../../common/constants";
const { RangePicker } = DatePicker;

function EmpCommissions(props) {
  const { showButton } = props;
  const [commissionData, setCommissionData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [properties, setProperties] = useState([]);
  const [plots, setPlots] = useState([]);
  const [rawCommissionData, setRawCommissionData] = useState([]);
  const [rawreceptdata, setRawCreceptData] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [commissionId, setCommissionId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comissionPayMode, setcomissionPayMode] = useState("Cash");
  const paymentModeOptions = [
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "Cheque",
      label: "Cheque",
    },
    {
      value: "GPay",
      label: "GPay",
    },
    {
      value: "Phone pay",
      label: "Phone pay",
    },
  ];

  async function fetchCommissionData() {
    const res = await getData(getCommissionDataAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setCommissionData(listItems);
      setRawCommissionData(listItems);
      const projectList = getProjectDropdownList(listItems);
      setProjects(projectList);
      // const propertyList = getPropertiesDropdownList(listItems);
      // setProperties(propertyList);
      // const plotsList = getPlotDropdownList(listItems);
      // setPlots(plotsList);
    }
  }

  useEffect(() => {
    fetchCommissionData();
  }, []);
  const columns = [
    {
      title: "Id",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
    },
    {
      title: "Sales ID ",
      dataIndex: "emp_id",
    },
    {
      title: "Sales Person Name ",
      dataIndex: "employeeName",
    },
    {
      title: "SP. Designation ",
      dataIndex: "EmployeeDesignation",
    },
    {
      title: "Project ",
      dataIndex: "project_name",
    },
    {
      title: "Property ",
      dataIndex: "property_name",
    },
    {
      title: "Plot # ",
      dataIndex: "plot_number",
    },
    {
      title: "Paid Amount",
      dataIndex: "total_amount_paid",
      render: (value) => formatPrice(value),
    },
    {
      title: "Due Amount",
      dataIndex: "total_amount_due",
      render: (value) => formatPrice(value),
    },
    {
      title: "Sold Date",
      dataIndex: "created_at",
    },
    {
      title: "Plot Status",
      dataIndex: "plot_status",
    },
  ];
  const receiptColums = [
    {
      title: "Receipt id",
      dataIndex: "p_id",
    },
    {
      title: "Paid Date",
      dataIndex: "created_at",
    },
    {
      title: "Paid amount",
      dataIndex: "amount_paid",
      render: (value) => formatPrice(value),
      align: "right",
    },
    {
      title: "Payment type",
      dataIndex: "payment_type",
    },
    {
      title: "Payment mode",
      dataIndex: "payment_mode",
    },
    {
      title: "Pay Commissions",
      dataIndex: "operation",
      width: 150,
      render: () => (
        <Button type="primary" className="ant-primary-btn approve">
          Pay
        </Button>
      ),
    },
  ];
  const paycolumns = [
    {
      title: "Agent Id",
      dataIndex: "emp_id",
    },
    {
      title: "Agent Name",
      dataIndex: "employeeName",
    },
    {
      title: "Designation",
      dataIndex: "employeeName",
    },
    {
      title: "withholding Tax (5%)",
      dataIndex: "employeeName",
    },
    {
      title: "Commission amount ",
      dataIndex: "commission_amount",
      render: (value) => formatPrice(value),
      align: "right",
    },
  ];

  const handleSubmit = async (values) => {
    try {
      var data = rawCommissionData.filter((item) => {
        return (
          item.project_id == values.project &&
          item.prop_id == values.property &&
          item.plot_number == values.plot
        );
      });
      setCommissionData(data);
    } catch {
      console.log("Catch block api error!");
    }
  };

  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };

  const handleProjectChange = (project) => {
    var data = rawCommissionData.filter((item) => {
      return item.project_id == project;
    });
    const propertyList = getPropertiesDropdownList(data);
    setProjectName(project);
    setProperties(propertyList);
  };
  const handlePropertyChange = (property) => {
    var data = rawCommissionData.filter((item) => {
      return item.project_id == projectName && item.prop_id == property;
    });
    const plotsList = getPlotDropdownList(data);
    setPropertyName(property);
    setPlots(plotsList);
  };
  const handleComissionPay = (item) => {
    setCommissionId(item.commission_id);
    setIsModalOpen(true);
    return;

    // window.location.replace(
    //   "/confirmcomissionpayment?receiptid=" + item.receipt_id
    // );
    // return;
  };
  const ReceiptDatagrid = (props) => {
    const { record } = props;
    const items = record.receiptData.map((item, index) => (
      <>
        <div className="cell receiptdata">
          <div className="flex-row maindata">
            <dl>
              <dd className="sl">{index + 1}</dd>
            </dl>
            <dl className="right-gutter-40">
              <dd>{item.payment_type}</dd>
            </dl>
            <dl className="cell">
              <dd className="right green">{formatPrice(item.amount_paid)}</dd>
            </dl>

            {/* <dl className="cell end">
              <dt>Receipt #:</dt>
              <dd></dd>
            </dl> */}
            <dl>
              <dd>{item.created_at}</dd>
            </dl>
          </div>
        </div>
        <div className="cell2 flex-column  commissiondet">
          <Commissiondatagrid record={item} />
          <div className="flex end top-gutter bottom-gutter butsec">
            {/* {showButton && (
            <Button type="primary" className="ant-primary-btn approve" onClick={() =>handleComissionPay(item)}>Pay</Button>
          )} */}
          </div>
        </div>
      </>
    ));
    return items;
  };
  const Commissiondatagrid = (props) => {
    const { record, designation } = props;
    const items = record.commissionData.map((item) => (
      <div className="flex-row">
        <dl className="flex-row cell">
          <dt>
            <span class="material-symbols-outlined size-32">
              account_circle
            </span>
          </dt>
          <dd className="flex-column">
            <b>
              {item.employeeName} ({item.emp_id})
            </b>
            <small>{item.EmployeeDesignation} </small>
          </dd>
        </dl>

        <dl>
          <dd className="flex-row">
            {formatPrice(item.commission_amount)}&nbsp;({item.agentPercentage}%)
          </dd>
        </dl>
        <dl>
          <dd className="red flex-row">
            - {formatPrice((5 * Number(item.commission_amount)) / 100)}&nbsp;(5%
            tax)
          </dd>
        </dl>

        <dl>
          <dd className="green">
            {formatPrice(
              item.commission_amount -
                Number((5 * Number(item.commission_amount)) / 100)
            )}
          </dd>
        </dl>
        <dl>
          <dd className={item.status == "pending" ? "red" : "green"}>
            {item.status}
          </dd>
        </dl>

        {showButton && (
          <dl>
            <dd className="green">
              <button
                type="primary"
                className={
                  item.status == "pending"
                    ? "ant-primary-btn approve"
                    : "disabled"
                }
                onClick={() => handleComissionPay(item)}
                disabled={item.status == "pending" ? "" : "disabled"}
              >
                Pay
              </button>
            </dd>
          </dl>
        )}
      </div>
    ));
    return items;
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCommissionPayChange = (value) => {
    setcomissionPayMode(value);
    return;
  };

  const handleCommissionSubmit = async () => {
    const payload = {
      commission_id: commissionId,
      payment_mode: comissionPayMode,
    };
    const response = await updateData(updateComissionPayAPI, payload);
    const result = await response.json();
    if (result && result.status === "success") {
      setIsModalOpen(false);
      fetchCommissionData();
    } else {
      alert('Error in API')
    }
    return;
  };
  return (
    <>
      <Form
        className="form flex-row nowidth  horizontal-form filterbg mob-flex-row"
        onFinish={handleSubmit}
        onFinishFailed={handleErrors}
      >
        <Form.Item
          className="cell "
          label="Project"
          name="project"
          rules={[
            {
              required: true,
              message: "Please select project",
            },
          ]}
        >
          <Select
            options={projects}
            onSelect={(value) => handleProjectChange(value)}
          ></Select>
        </Form.Item>
        <Form.Item
          className="cell "
          label="Property"
          name="property"
          rules={[
            {
              required: true,
              message: "Please select project",
            },
          ]}
        >
          <Select
            options={properties}
            onSelect={(value) => handlePropertyChange(value)}
          >
            {" "}
          </Select>
        </Form.Item>

        <Form.Item
          className="cell"
          label="Plot"
          name="plot"
          rules={[
            {
              required: true,
              message: "Please property",
            },
          ]}
        >
          <Select options={plots}></Select>
        </Form.Item>
        <Button htmlType="submit" type="primary">
          Search
        </Button>
      </Form>

      <Table
        columns={columns}
        dataSource={commissionData}
        pagination={false}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => (
            <>
              <div className="flex-row paymentdata">
                <span>{record.receipt_id}</span>
                <ReceiptDatagrid record={record} />
              </div>

              {/* `    <div className="flex">
                        <Table
                          columns={receiptColums}
                          rowKey="p_id"
                          dataSource={record.receiptData}
                          pagination={false}
                          expandable={{
                            expandedRowRender: (record) => (
                              <>
                                <div className="flex">
                                  <Table
                                    rowkey="commission_id"
                                    columns={paycolumns}
                                    pagination={false}
                                    dataSource={record.commissionData}
                                  />
                                </div>
                              </>
                            ),
                          }}
                        />
                      </div>` */}
            </>
          ),
        }}
      />
      <Modal
        title="Select payment mode"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Form>
          <Form.Item
            className="cell "
            label="Payment mode"
            name="payment_mode"
            rules={[
              {
                required: true,
                message: "Please select payment mode",
              },
            ]}
          >
            <Select
              options={paymentModeOptions}
              onSelect={(value) => handleCommissionPayChange(value)}
            ></Select>
          </Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            onClick={handleCommissionSubmit}
          >
            Confirm Payment
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default EmpCommissions;
