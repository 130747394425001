import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { getDataWithPayload } from "../../services/services";
import { getPlotDetailsByIDAPI } from "../../common/constants";

function PlotInDetails() {
    const [plot, setPlot] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    async function fetchPlot() {
        const plot_id = searchParams.get("plot_id");
        const res = await getDataWithPayload(getPlotDetailsByIDAPI, {
            plot_id: plot_id,
        });
        const response = await res.json();
        if (response.status === "success") {
            const listItems = response.data;
            setPlot(listItems[0]);
        }
    }

    useEffect(() => {
        fetchPlot();
    }, []);

    return (

        <div className="propertydetails">
            <div className="flex-row">
                <div className="flex-column bottom-gutter-20 cell right-gutter-40 top">
                    <div className="flex flex-row cell fullwidth">
                        <article className="totalproperties cell ">
                     
                            <div className="cell flex-column sale">
                                <h5>Plot Price</h5>
                                <strong>₹ {plot.total_price} </strong>
                            </div>
                            <h5 className="end">   <span class="material-symbols-outlined size-32">real_estate_agent</span></h5>
                        </article>

                    </div>
                    <div className="flex-row top fullwidth">
                        <div className="flex-column ">
                            <article class="employer cell">
                                <h3>Plan A - Total Price</h3>
                                <strong>₹ {plot.total_price} </strong>

                                <dl>
                                    <dt>Booking Amount</dt>
                                    <dd>{plot.booking_amount}</dd>
                                </dl>
                                <dl>
                                    <dt>Agreement Amount</dt>
                                    <dd>{plot.agreement_amount}</dd>
                                </dl>
                                <dl>
                                    <dt>Registration Amount</dt>
                                    <dd>{plot.registration_amount}</dd>
                                </dl>
                            </article>
                            <article class="employer cell">
                                <h3>Plan B - Total Price</h3>
                                <strong>₹ {plot.plan_b_total_emi_amount} </strong>
                                <dl>
                                    <dt>Booking Amount</dt>
                                    <dd>{plot.emi_booking_amount}</dd>
                                </dl>
                                <dl>
                                    <dt>Total Number of EMI's</dt>
                                    <dd>{plot.number_of_emis}</dd>
                                </dl>
                                <dl>
                                    <dt>Emi amount per month</dt>
                                    <dd>{plot.emi_amount}</dd>
                                </dl>
                                <dl>
                                    <dt>Registration Amount</dt>
                                    <dd>{plot.emi_registration_amount}</dd>
                                </dl>
                            </article>
                        </div>
                        <div className="flex flex-column cell fullwidth left-pad-50">

                            <article class="employer">
                                <h4>Project Details</h4>

                                <dl>
                                    <dt>Project</dt>
                                    <dd>{plot.project_id}</dd>
                                </dl>
                                <dl>
                                    <dt>Property</dt>
                                    <dd></dd>
                                    {plot.property_id}
                                </dl>
                            </article>
                            <article class="employer">
                                <h5>Plot Details</h5>
                                <dl>
                                    <dt>Plot #</dt>
                                    <dd>{plot.plot_number}</dd>
                                </dl>
                                <dl>
                                    <dt>Square Yard</dt>
                                    <dd>{plot.square_yards}</dd>
                                </dl>
                            </article>
                            <article class="employer">
                                <h5>Plot Status</h5>
                                <dl>
                                    <dt>Status</dt>
                                    <dd>{plot.plot_status}</dd>
                                </dl>
                            </article>
                            <article class="employer">
                                <h5>Address Details</h5>
                                <dl>
                                    <dt>Address 1</dt>
                                    <dd>{plot.address1}</dd>
                                </dl>
                                <dl>
                                    <dt>Address 2</dt>
                                    <dd>{plot.address2}</dd>
                                </dl>
                                <dl>
                                    <dt>city</dt>
                                    <dd>{plot.city}</dd>
                                </dl>
                                <dl>
                                    <dt>state</dt>
                                    <dd>{plot.state}</dd>
                                </dl>
                                <dl>
                                    <dt>Longitude</dt>
                                    <dd>{plot.Longitude}</dd>
                                </dl>
                                <dl>
                                    <dt>Latitude</dt>
                                    <dd>{plot.Latitude}</dd>
                                </dl>
                            </article>
                        </div>
                    </div>
                    {/* <div className="flex flex-column cell propertylist">
                <article>
                  <p>
                    <strong>Map Area</strong>
                  </p>
                  <figure>
                    <img src="https://omah.dexignzone.com/codeigniter/demo/public/assets/images/property/1.jpg" />
                  </figure>
                </article>
              </div> */}
                </div>
            </div>

        </div>
    );
}

export default PlotInDetails;
