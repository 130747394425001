import React, { useState, useEffect } from "react";
import { Space, Table, Button, Form, Select, DatePicker } from "antd";
import AdminHeader from "../shared/header";

import {
  getData,
  getProjectDropdownList,
  getPropertiesDropdownList,
  getPlotDropdownList,
  formatPrice,
} from "../../../services/services";
import { getCommissionDataAPI } from "../../../common/constants";
import EmpCommissions from "../../shared/comissions";
const { RangePicker } = DatePicker;
function EmployeeSoldDetails() {
  const [commissionData, setCommissionData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [properties, setProperties] = useState([]);
  const [plots, setPlots] = useState([]);
  const [rawCommissionData, setRawCommissionData] = useState([]);

  async function fetchCommissionData() {
    const res = await getData(getCommissionDataAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setCommissionData(listItems);
      setRawCommissionData(listItems);
      const projectList = getProjectDropdownList(listItems);
      setProjects(projectList);
      const propertyList = getPropertiesDropdownList(listItems);
      setProperties(propertyList);
      const plotsList = getPlotDropdownList(listItems);
      setPlots(plotsList);
    }
  }

  useEffect(() => {
    fetchCommissionData();
  }, []);
  const columns = [
    {
      title: "Sold Date",
      dataIndex: "created_at",
    },
    {
      title: "Project ",
      dataIndex: "project_name",
    },
    {
      title: "Property ",
      dataIndex: "property_name",
    },
    {
      title: "Plot # ",
      dataIndex: "plot_number",
    },
  ];
  const receiptColums = [
    {
      title: "Receipt id",
      dataIndex: "p_id",
    },
    {
      title: "Paid Date",
      dataIndex: "created_at",
    },
    {
      title: "Paid amount",
      dataIndex: "amount_paid",
      render: (value) => formatPrice(value),
    },
    {
      title: "Plan type",
      dataIndex: "payment_type",
    },
    {
      title: "Payment mode",
      dataIndex: "payment_mode",
    },
  ];
  const paycolumns = [
    {
      title: "Emp Id",
      dataIndex: "emp_id",
    },
    {
      title: "Emp Name",
      dataIndex: "employeeName",
    },
    {
      title: "Commission amount ",
      dataIndex: "commission_amount",
      render: (value) => formatPrice(value),
    },
  ];

  const handleSubmit = async (values) => {
    try {
      var data = rawCommissionData.filter((item) => {
        return (
          item.project_id == values.project &&
          item.prop_id == values.property &&
          item.plot_number == values.plot
        );
      });
      setCommissionData(data);
    } catch {
      console.log("Catch block api error!");
    }
  };

  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };

  return (
    <div className="admin ">
      <div className="flex flex-row fullheight">
        <div className="admin-dashboard">
          <AdminHeader></AdminHeader>

          <div className="flex-row cell">
            <div className="bodycontainer">
              <div className="flex flex-row vcenter bottom-gutter-20">
                <h1 className="cell">Payments to the Marketing Leaders</h1>
              </div>
              <EmpCommissions showButton={true}></EmpCommissions>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeSoldDetails;
