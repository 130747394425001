import React, { useState, useEffect } from "react";
import {
  Button,
  Spin,
  Select,
  Form,
  Input,
  DatePicker,
  CollapseProps,
  Upload,
} from "antd";
import dayjs from "dayjs";
import { Collapse } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { updateData, getDataWithPayload, getEmployeeDropdownList, getData, addOrupdateDataWithImage } from "../../services/services";
import {
  updateCustomerAPI,
  registerCustomerAPI,
  states,
  customerDetailsByIDAPI,
  employeesListAPI,
} from "../../common/constants";
import { useSearchParams } from "react-router-dom";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

function AddEditCustomer() {
  const [form] = Form.useForm();
  const [customer_id, setCustomerId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [locations, setLocations] = useState([]);
  const [referrenceList, setReferrenceList] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleUpload = (info) => {
    if (info.file.status === "done") {
      const fileObj = info.file.originFileObj;
      setFile(fileObj);
    }
  };

  async function fetchCustomerData(id) {
    const res = await getDataWithPayload(customerDetailsByIDAPI, {
      customer_id: id,
    });
    const response = await res.json();
    if (response.status === "success") {
      setData(response.data);
      const d = response.data;
      const dateofbirth = dayjs(d.dob, "DD/MM/YYYY")
      form.setFieldValue("name", d.name);
      form.setFieldValue("father_husband_name", d.father_husband_name);
      form.setFieldValue("relationship", d.relationship);
      form.setFieldValue("occupation", d.occupation);
      form.setFieldValue("dob", dateofbirth);
      form.setFieldValue("adhaar", d.adhaar);
      form.setFieldValue("pan", d.pan);
      form.setFieldValue("profile_pic", d.profile_pic);
      form.setFieldValue("employee_id", d.employee_id);

      form.setFieldValue("email", d.email);
      form.setFieldValue("mobile", d.mobile);
      form.setFieldValue("address1", d.address1);
      form.setFieldValue("address2", d.address2);
      form.setFieldValue("city", d.city);
      form.setFieldValue("state", d.state);
    }
  }
  useEffect(() => {
    const customer_id = searchParams.get("customer_id");
    setCustomerId(customer_id);
    if (customer_id) {
      fetchCustomerData(customer_id);
    }
  }, []);
  useEffect(() => {
    async function fetchEmployeesList() {
      const res = await getData(employeesListAPI);
      const response = await res.json();
      if (response.status === "success") {
        const listItems = response.data;
        const list = getEmployeeDropdownList(listItems);
        setReferrenceList(list);
      }
    }
    fetchEmployeesList();
  }, []);
  function Demographic() {
    return (
      <>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Father / Husband Name"
          name="father_husband_name"
          rules={[
            {
              required: true,
              message: "Please input your father / husband name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Relationship"
          name="relationship"
          rules={[
            {
              required: true,
              message: "Please input your relationship!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Occupation"
          name="occupation"
          rules={[
            {
              required: true,
              message: "Please input your occupation!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="DOB"
          name="dob"
          rules={[
            {
              required: true,
              message: "Please input your date of birth!",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Adhar Number"
          name="adhaar"
          rules={[
            {
              required: true,
              message: "Please input adhaar!",
            },
            {
              pattern: /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/,
              message: "Please enter valid aadhar number",
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="PAN"
          name="pan"
          rules={[
            {
              required: true,
              message: "Please input your pan!",
            },
          ]}
        >
          <Input />
        </Form.Item>
         <Form.Item label="Profile Picture" name="customer_pic">
                <Upload
                  onChange={handleUpload}
                  action="https://srilakshmiagrofarms.co.in/webapplication/api/add-customer"
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>Upload Profile Picture</Button>
                </Upload>
              </Form.Item>
        <Form.Item
          label="Employee Name(Refference)"
          name="employee_id"
          rules={[
            {
              required: true,
              message: "Please input your Refference!",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={referrenceList}
          />
        </Form.Item>
      </>
    );
  }
  function Contact() {
    return (
      <>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mobile"
          name="mobile"
          rules={[
            {
              required: true,
              message: "Please input your mobile!",
            },
            {
              pattern: /^[\d]{10,10}$/,
              message: "Mobile should be 10 digits",
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address 1"
          name="address1"
          rules={[
            {
              required: true,
              message: "Please input your address 1!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address 2"
          name="address2"
         
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: "Please input your City!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
              message: "Please input your St!",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={states}
          />
        </Form.Item>
      </>
    );
  }

  const handleSubmit = async (values) => {
    try {
      setHasError(false);
      setIsLoading(true);

      let endpoint;
      if (data.customer_id) {
        endpoint = updateCustomerAPI;
      } else {
        endpoint = registerCustomerAPI;
      }
      
      const formData = new FormData();
      const dateofbirth = dayjs(values.dob, "YYYY-MM-DD")
      formData.append("name", values.name);
      formData.append("email", values.email)
      formData.append("father_husband_name", values.father_husband_name);
      formData.append("relationship", values.relationship);
      formData.append("occupation", values.occupation);
      formData.append("dob", dateofbirth);
      formData.append("adhaar", values.adhaar);
      formData.append("pan", values.pan);
      formData.append("employee_id", values.employee_id);
      formData.append("mobile", values.mobile);
      formData.append("address1", values.address1);
      formData.append("address2", values.address2);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("customer_id", data.customer_id);
      if (file) {
        formData.append("customer_pic", file);
      }
      const response = await addOrupdateDataWithImage(endpoint, formData);
      const result = await response.json();
      if (result && result.status === "success") {
        setIsLoading(false);
        if (data.customer_id) window.location.replace("/customerlist");
        else window.location.replace("/customerlogin");
        return;
      } else {
        setIsLoading(false)
        setHasError(true);
      }
    } catch {
      setIsLoading(false);
      setHasError(true);
      console.log("Catch block register api error!");
    }
  };

  //   const handleCancel = () => {
  //     window.location.replace("/teamlist");
  //     return;
  //   }

  //Error handling
  const handleErrors = (errorInfo) => {
    alert("Please enter mandatory input in all tabs");
    return false;
  };
  if (!customer_id || (customer_id && data.customer_id))
    return (
      <div className="admin" style={{background:"none", marginTop:"20px"}}>
        <div className="admin-dashboard flex flex-column cell">
          <div className="emp-view">
            <div className="accordion ">
              <Form
                className="form"
                layout="vertical"
                onFinish={handleSubmit}
                onFinishFailed={handleErrors}
                form={form}
              >
                  <div
                    className="scrollform"
                    style={{ height: "calc(100vh - 298px)" }}
                  >
                {/* <Collapse accordion items={items} defaultActiveKey={["1"]} /> */}
                <h3 className="sectitle">Demographic Details</h3>
                <Demographic />
                <h3 className="sectitle">Contact Details</h3>
                <Contact />
                </div>
                {hasError && <ErrorComponent errorMessage={"Error in API. Please try after sometime"} />}
                {isLoading && <Spin />}
                <div className="flex flex-row top-gutter-20 end">
                  <Button
                    type="secondary"
                    htmlType="submit"
                    className="right-gutter bottom-gutter-20 self-end"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AddEditCustomer;
