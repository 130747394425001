
import React, { useState } from 'react';
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Menu, Button } from 'antd';
import { Link } from 'react-router-dom';

function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [

    {
      label: (<Link to='/employeedashboard'>Dashoard</Link>),
      key: 'db',
      icon: <MailOutlined />,
    },

    {
      label: 'Team',
      key: 'SubMenu',
      icon: <SettingOutlined />,
      children: [
        {
          label: (<Link to='/employers'>View Team Members</Link>),

        },
        {
          label:(<Link to='/addteammember'>Add Team Member</Link>),

        },

      ],
    },
    {
      label: 'Properties',
      key: 'propSubMenu',
      icon: <SettingOutlined />,
      children: [
        {
          label: (<Link to='/propertylist'>Property List</Link>),

        },
        // {
        //   label:(<Link to='/addproperty'>Add Property</Link>),

        // },

      ],
    },
    {
      label: (<Link to='/paymenthistory'>Payments</Link>),
      key: 'payments',
      icon: <MailOutlined />,
    },
    {
      label: (<Link to='/todaybusiness'>Today' Business</Link>),
      key: 'today',
      icon: <MailOutlined />,
    },
    {
      label: (<Link to='/profile'>Profile</Link>),
      key: 'prof',
      icon: <MailOutlined />,
    },
   
   
  ];
function EmployeeNav() {
    const onClick = (e) => {
        console.log('click ', e);
      };
      const [collapsed, setCollapsed] = useState(true);

      const toggleCollapsed = () => {
        setCollapsed(!collapsed);
      };
    return (
      <div className='flex-column nav'>
      <Button type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
      {collapsed ?<MenuFoldOutlined />  : <MenuUnfoldOutlined />}
    </Button>
        <Menu
        onClick={onClick}
      
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        inlineCollapsed={collapsed}
        items={items}
      />
      </div>
    );
}

export default EmployeeNav;
