import React, { useEffect, useState } from "react";
import AdminHeader from "../shared/header";
import EmployeeNav from "../shared/nav";
import TeamBusiness from "../../shared/teambusiness";
import { getDataWithPayload } from "../../../services/services";
import { employeeTeamBusinessAPI } from "../../../common/constants";

function TodayBusiness() {
  const [todayBussinessHistory, setTodayBussinessHistory] = useState([]);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("userDetails"));
    if (!loginData || !loginData.name) {
      window.location.replace("/employeelogin");
      return;
    }
    fetchTeamPaymentHistory();
  }, []);

  async function fetchTeamPaymentHistory() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const res = await getDataWithPayload(employeeTeamBusinessAPI, {
      reference: userDetails.emp_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.teambusiness;
      let currentDate = new Date().toJSON().slice(0, 10);
      console.log(currentDate);
      var items = listItems.filter((item) => { return item.created_at == currentDate} );
      console.log(JSON.stringify(items));
      setTodayBussinessHistory(items);
    }
  }

  return (
    <div className="employee emp-dashboard flex flex-column cell">
      <AdminHeader></AdminHeader>
      <div className="flex-row cell">
        <EmployeeNav></EmployeeNav>
        <div className="bodycontainer">
          <h2>Today's Business</h2>
          <div className="flex-row top-gutter-20">
            <article className="whitearticle cell">
              <TeamBusiness data={todayBussinessHistory}></TeamBusiness>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TodayBusiness;
