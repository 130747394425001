import React, { useState, useEffect } from "react";

import {
  Button,
  Checkbox,
  Select,
  Form,
  Input,
  DatePicker,
  CollapseProps,
  Spin,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  getDataWithPayload,
  getData,
  getEmployeeDropdownList,
  updateData,
  getRolesDropdownList,
  addOrupdateDataWithImage,
} from "../../services/services";
import {
  employeeDetailsByIDAPI,
  locationAPI,
  employeesListAPI,
  states,
  updateEmployeeAPI,
  registerAPI,
  adminRolesListAPI,
} from "../../common/constants";
import dayjs from "dayjs";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

import { Collapse } from "antd";
function SharedEditEmployer(props) {
  const { empID } = props;
  const [data, setData] = useState({});
  const [locations, setLocations] = useState([]);
  const [referrenceList, setReferrenceList] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [designationsList, setDesignationsList] = useState([]);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = (info) => {
    if (info.file.status === "done") {
      const fileObj = info.file.originFileObj;
      setFile(fileObj);
    }
  };

  let emp_id = "";
  let loadingValue = false;
  if (empID) {
    emp_id = empID.replace("_", "/");
  } else {
    loadingValue = true;
  }
  const [loading, setLoading] = useState(loadingValue);
  async function fetchEmployeeData() {
    const res = await getDataWithPayload(employeeDetailsByIDAPI, {
      emp_id: emp_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      setData(response.data);
      setLoading(true);
    }
  }

  async function fetchLocations() {
    const res = await getData(locationAPI);
    const response = await res.json();
    setLocations(response.locations);
  }

  async function fetchEmployeesList() {
    const res = await getData(employeesListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      const list = getEmployeeDropdownList(listItems);
      setReferrenceList(list);
    }
  }

  useEffect(() => {
    if (emp_id) {
      fetchEmployeeData();
    }
  }, [emp_id]);

  useEffect(() => {
    fetchLocations();
    fetchEmployeesList();
    fetchRoles();
  }, []);
  const items: CollapseProps[] = [
    {
      key: "1",
      label: "Demographic Details",
      children: <Demographic />,
    },
    {
      key: "2",
      label: "Contact Details",
      children: <Contact />,
    },
    {
      key: "3",
      label: "Address Details",
      children: <Address />,
    },
    {
      key: "4",
      label: "Bank Details",
      children: <Bank />,
    },
    {
      key: "5",
      label: "Login Details",
      children: <Credentials />,
    },
  ];
  const statusList = [
    { value: "active", label: <span>ACTIVE</span> },
    { value: "inactive", label: <span>INACTIVE</span> },
  ];
  const genderData = [
    { value: "MALE", label: <span>MALE</span> },
    { value: "FEMALE", label: <span>FEMALE</span> },
  ];
  const dateFormat = "YYYY-MM-DD";
  // const designationsList = [
  //   { value: "CEO", label: <span>CEO</span> },
  //   { value: "Manager", label: <span>Manager</span> },
  // ];

  async function fetchRoles() {
    const res = await getData(adminRolesListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      const Items = getRolesDropdownList(listItems);
      setDesignationsList(Items);
    }
  }

  function Demographic() {
    return (
      <>
        <Form.Item
          className="cell right-gutter"
          label="First Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your firstname!",
            },
          ]}
        >
          <Input value={"sdadas"} />
        </Form.Item>
        <Form.Item
          className="cell"
          label="Last Name"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please input your lastname!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Gender"
          name="gender"
          rules={[
            {
              required: true,
              message: "Please input Gender!",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={genderData}
          />
        </Form.Item>

        <Form.Item
          label="Date of Birth"
          name="dateofbirth"
          rules={[
            {
              required: true,
              message: "Please input your DOB!",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label="Working Location"
          name="location"
          rules={[
            {
              required: true,
              message: "Please input your Location!",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={locations}
          />
        </Form.Item>
        <Form.Item
          label="Designation"
          name="designation"
          rules={[
            {
              required: true,
              message: "Please input your Location!",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={designationsList}
          />
        </Form.Item>
        <Form.Item
          label="Status"
          name="delete_status"
          rules={[
            {
              required: true,
              message: "Please input your status!",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={statusList}
          />
        </Form.Item>
        <Form.Item
          label="Introducer ID"
          name="reference"
          rules={[
            {
              required: true,
              message: "Please input your Introducer id!",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={referrenceList}
          />
        </Form.Item>

        <Form.Item label="Profile Picture" name="profile_pic">
          <Upload
            onChange={handleUpload}
            action={updateEmployeeAPI}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Upload Profile Picture</Button>
          </Upload>
        </Form.Item>
      </>
    );
  }
  function Contact() {
    return (
      <>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mobile"
          name="mobile_number"
          rules={[
            {
              required: true,
              message: "Please input your mobile!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Alternative Mobile" name="alternative_mobile">
          <Input />
        </Form.Item>
      </>
    );
  }
  function Address() {
    return (
      <>
        <Form.Item
          label="Address 1"
          name="address1"
          rules={[
            {
              required: true,
              message: "Please input your address 1!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Address 2" name="address2">
          <Input />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: "Please input your City!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
              message: "Please input your St!",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={states}
          />
        </Form.Item>
      </>
    );
  }
  function Bank() {
    return (
      <>
        <Form.Item
          label="Account No"
          name="bank_account"
          rules={[
            {
              required: true,
              message: "Please input Account No!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Branch"
          name="branch"
          rules={[
            {
              required: true,
              message: "Please input your branch!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="IFSC Code"
          name="ifsc"
          rules={[
            {
              required: true,
              message: "Please input IFSC code!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </>
    );
  }
  function Credentials() {
    return (
      <>
        <Form.Item
          label="user ID"
          name="emp_id"
          rules={[
            {
              required: true,
              message: "Please input Account No!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input />
        </Form.Item>
      </>
    );
  }

  const handleSubmit = async (values) => {
    try {
      setHasError(false);
      setIsLoading(true);
      const formData = new FormData();
      const dateofbirth = dayjs(values.dateofbirth, "YYYY-MM-DD");
      formData.append("id", data.id);
      formData.append("name", values.name);
      formData.append("last_name", values.last_name);
      formData.append("gender", values.gender);
      formData.append("dateofbirth", dateofbirth);
      formData.append("location", values.location);
      formData.append("designation", values.designation);
      formData.append("reference", values.reference);
      formData.append("email", values.email);
      formData.append("mobile_number", values.mobile_number);
      formData.append("alternative_mobile", values.alternative_mobile);
      formData.append("address1", values.address1);
      formData.append("address2", values.address2);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("bank_account", values.bank_account);
      formData.append("branch", values.branch);
      formData.append("ifsc", values.ifsc);
      formData.append("emp_id", data.emp_id);
      formData.append("password", values.password);
      formData.append("delete_status", values.delete_status);

      if (file) {
        formData.append("profile_pic", file);
      }

      let endpoint;
      if (data.emp_id) {
        endpoint = updateEmployeeAPI;
        if (data.delete_status == "pending") {
          formData.append("type", "approve");
        } else {
          formData.append("type", "update");
        }
      } else {
        endpoint = registerAPI;
      }
      // alert(endpoint, payload);
      const response = await addOrupdateDataWithImage(endpoint, formData);
      const result = await response.json();
      if (result && result.status === "success") {
        setIsLoading(false);
        if (data.emp_id && window.location.href.indexOf("profile") > -1)
          window.location.replace("/employeedashboard");
        else window.location.replace("/teamlist");
        return;
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    } catch {
      setHasError(true);
      setIsLoading(false);
      console.log("Catch block register api error!");
    }
  };

  const handleCancel = () => {
    window.location.replace("/teamlist");
    return;
  };

  //Error handling
  const handleErrors = (errorInfo) => {
    alert("Please enter mandatory input in all tabs");
    return false;
  };

  if (!loading) return <span>Loading....</span>;
  if (!empID || (empID && data.name)) {
    return (
      <Form
        className="form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleErrors}
        initialValues={{
          name: data.name,
          last_name: data.last_name,
          gender: data.gender,
          dateofbirth: data.dateofbirth
            ? dayjs(data.dateofbirth, dateFormat)
            : "",
          location: data.location,
          designation: data.designation,
          reference: data.reference,
          profile_pic: data.profile_pic,
          email: data.email,
          mobile_number: data.mobile_number,
          alternative_mobile: data.alternative_mobile,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          bank_account: data.bank_account,
          branch: data.branch,
          ifsc: data.ifsc,
          emp_id: data.emp_id,
          password: data.password,
          delete_status: data.delete_status,
        }}
      >
        <div className="flex flex-row vcenter top-gutter">
          {/* <h1 className="cell">Add / Edit Team Member</h1> */}
          {hasError && <ErrorComponent errorMessage={"Error in api"} />}
          {isLoading && <Spin />}
          <Button
            type="primary"
            htmlType="submit"
            className="right-gutter bottom-gutter-20 self-end"
          >
            Save
          </Button>
          <Button
            type="primary"
            className="right-gutter bottom-gutter-20 self-end"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          {/* <Button type="primary">Clear</Button> */}
        </div>
        {/* <Collapse accordion items={items} defaultActiveKey={["1"]} /> */}
        <h3 className="sectitle">Demographic Details</h3>
        <Demographic />
        <h3 className="sectitle">Contact Details</h3>
        <Contact />
        <h3 className="sectitle">Address Details</h3>
        <Address />
        <h3 className="sectitle">Bank Details</h3>
        <Bank />
        <h3 className="sectitle">Credential Details</h3>
        <Credentials />
      </Form>
    );
  }
}

export default SharedEditEmployer;
