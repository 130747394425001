import React, { useState, useEffect } from "react";
import { Space, Table, Tag } from "antd";
import { useSearchParams } from "react-router-dom";

import AdminHeader from "../shared/header";
import { Button, Checkbox, Form, Input, Spin } from "antd";
import EmployeeNav from "../shared/nav";
import { Link } from "react-router-dom";
import { getDataWithPayload } from "../../../services/services";
import { employeeDetailsByIDAPI } from "../../../common/constants";

function EmployeeEmpoyerDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [empID, setEmpID] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  async function fetchEmployeeData(id) {
    const res = await getDataWithPayload(employeeDetailsByIDAPI, {
      emp_id: id,
    });
    const response = await res.json();
    if (response.status === "success") {
      setData(response.data);
      setLoading(false);
    }
  }

  useEffect(() => {
    const emp_id = searchParams.get("emp_id");
    setEmpID(emp_id);
    console.log(empID);
    const id = empID.replace("_", "/");
    if (id) fetchEmployeeData(id);
  }, [empID]);

  const biscolumns = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "project",
      dataIndex: "project",
      key: "project",
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Sale by",
      dataIndex: "emp",
      key: "project",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "project",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "project",
      render: (text) => <span>{text}</span>,
    },
  ];
  const bisdata = [
    {
      key: "1",
      id: "1",
      emp: "John",
      Price: "$600",
      status: "pending",
      project: "Project 1",
      location: "Hyderabd",
    },
  ];
  if (loading) return <span>loading....</span>;
  if (!loading)
    return (
      <div className="employee emp-dashboard flex flex-column cell">
        <AdminHeader></AdminHeader>
        <div className="flex-row cell">
          <EmployeeNav></EmployeeNav>
          <div className="bodycontainer">

            <div className="flex-row">
              <div className="flex-column bottom-gutter-20 cell right-gutter-40">
                <article className="totalproperties cell">
                  <figure className="cell">
                    <img src="https://omah.dexignzone.com/codeigniter/demo/public/assets/images/users/pic1.jpg" />
                    <figcaption>
                    {data.name} <small>{data.designation}</small>
                    </figcaption>
                  </figure>
                  <div className="flex end">
                    <Link
                      to={"/addteammember?emp_id=" + empID}
                      className="iconbutton"
                    >
                      <span class="material-symbols-outlined size-32">
                        edit
                      </span>
                    </Link>
                  </div>
                </article>
                <div className="flex-row wrap mob-flex-row">
                  <article className="employer employerdet">
                    {/* <div className='flex end'>
                                        <button className='iconbutton'><span class="material-symbols-outlined size-32">
                                            edit
                                        </span></button>
                                    </div> */}
                    <h4>Demographic Details</h4>
                    <dl>
                      <dt>First Name</dt>
                      <dd>{data.name}</dd>
                    </dl>
                    <dl>
                      <dt>Last Name</dt>
                      <dd>{data.last_name}</dd>
                    </dl>

                    <dl>
                      <dt>ID</dt>
                      <dd>{data.emp_id}</dd>
                    </dl>
                    <dl>
                      <dt>Introducer ID</dt>
                      <dd>{data.reference}</dd>
                    </dl>
                    <dl>
                      <dt>Date of Birth</dt>
                      <dd>{data.dateofbirth}</dd>
                    </dl>
                    <dl>
                      <dt>Gender</dt>
                      <dd>{data.gender}</dd>
                    </dl>
                  </article>
                  <article className="employer employerdet">
                    {/* <div className='flex end'>
                                        <button className='iconbutton'><span class="material-symbols-outlined size-32">
                                            edit
                                        </span></button>
                                    </div> */}
                    <h4>Contact Details</h4>

                    <dl>
                      <dt>Email</dt>
                      <dd>{data.email}</dd>
                    </dl>
                    <dl>
                      <dt>Mobile</dt>
                      <dd>{data.mobile_number}</dd>
                    </dl>
                    <dl>
                      <dt>Alternative Mobile</dt>
                      <dd>{data.alternative_mobile}</dd>
                    </dl>
                  </article>
                  <article className="employer employerdet">
                    {/* <div className='flex end'>
                                        <button className='iconbutton'><span class="material-symbols-outlined size-32">
                                            edit
                                        </span></button>
                                    </div> */}
                    <h4>Address Details</h4>
                    <dl>
                      <dt>Address 1</dt>
                      <dd>{data.address1}</dd>
                    </dl>
                    <dl>
                      <dt>Address 2</dt>
                      <dd>{data.address2}</dd>
                    </dl>
                    <dl>
                      <dt>City</dt>
                      <dd>{data.city}</dd>
                    </dl>
                    <dl>
                      <dt>State</dt>
                      <dd>{data.state}</dd>
                    </dl>
                    <dl>
                      <dt>Country</dt>
                      <dd>India</dd>
                    </dl>
                    <div className="flex vcenter top-gutter-20 middle"></div>
                  </article>
                  <article className="employer employerdet">
                    {/* <div className='flex end'>
                                        <button className='iconbutton'><span class="material-symbols-outlined size-32">
                                            edit
                                        </span></button>
                                    </div> */}
                    <h4>Bank Details</h4>
                    <dl>
                      <dt>Account No: </dt>
                      <dd>{data.bank_account}</dd>
                    </dl>
                    <dl>
                      <dt>Branch</dt>
                      <dd>{data.branch}</dd>
                    </dl>
                    <dl>
                      <dt>IFSC Code</dt>
                      <dd>{data.ifsc}</dd>
                    </dl>
                  </article>
                </div>
              

              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default EmployeeEmpoyerDetails;
