import React, { useState, useEffect } from "react";
import { Button } from 'antd';
import {
  getDataWithPayload,
  formatPrice,
} from "../../../services/services";
import { getCommissionDataByIdAPI } from "../../../common/constants";
import { useSearchParams } from "react-router-dom";
import AdminHeader from "../shared/header";


function ConfirmComissionPayment() {
  const [searchParams, setSearchParams] = useSearchParams();  
  const receiptid = searchParams.get("receiptid");
  const [comissionData, setCommissionData] = useState([]);

  async function fetchCommissionData() {
    const receiptid = searchParams.get("receiptid");
    const res = await getDataWithPayload(getCommissionDataByIdAPI, {
      receipt_id: receiptid,
    });
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setCommissionData(listItems)
    }
  }


  useEffect(() => {
    if (receiptid) {
      fetchCommissionData();
    }
  }, [receiptid]);

  const GridData = (props) => {
    const { data } = props;
    const listItems = data.map((item,index) => (
      <tr>
            <td>{index+1}</td>
            <td>{item.employeeName}</td>
            <td align="right">{formatPrice(item.commission_amount)}</td>
            <td  className="red" align="right">{formatPrice(5 * Number(item.commission_amount) / 100)}</td>
            <td align="right">{formatPrice(Number(item.commission_amount) - Number(5 * Number(item.commission_amount) / 100))}</td>
          </tr>
    ));
    return listItems;
  };



if(comissionData.length > 0)
  return (
    <div className="admin ">
      <div className="flex flex-row fullheight">
        <div className="admin-dashboard">
          <AdminHeader></AdminHeader>

          <div className="flex-row cell">
            <div className="bodycontainer">
              <div className="flex flex-row vcenter bottom-gutter-20">
                <h1 className="cell">confirm Payments to the Marketing Leaders</h1>
              </div>
              <div className="flex-row">
    <table className="statictable">
          <tr>
            <th>Sno</th>
            <th>Employee name</th>
            <th>Comission amount</th>
            <th>tax amount</th>
            <th>Total comission</th>
          </tr>
          <GridData data={comissionData} />
        </table>
      </div>
      <div className="flex end right-gutter-40 top-gutter-40"><Button type="primary">Confirm Payment</Button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmComissionPayment;
