import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Select,
  Form,
  Input,
  DatePicker,
  message,
  Steps,
  theme,
  Upload,
} from "antd";
import AdminHeader from "../shared/header";
import EmployeeNav from "../shared/nav";
import { UploadOutlined } from "@ant-design/icons";
import SharedEditEmployer from "../../shared/addeditemployer";
function AddEditEmployer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [empID, setEmpID] = useState("");
  useEffect(() => {
    const emp_id = searchParams.get("emp_id");
    setEmpID(emp_id);
  });
  return (
    <div className="employee emp-dashboard flex flex-column cell">
      <AdminHeader></AdminHeader>
      <div className="flex-row cell">
        <EmployeeNav></EmployeeNav>
        <div className="bodycontainer nobgform">
          <h2>Add Member</h2>
          <div className="admin">
            <div className="admin-dashboard">
              <div className="emp-view">
                <div className="accordion ">
                  <SharedEditEmployer empID={empID}></SharedEditEmployer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEditEmployer;
