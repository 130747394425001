import React, { useState, useEffect } from "react";
import {
  getData,
  getProjectDropdownList,
  getPropertiesDropdownList,
  addData,
  getDataWithPayload,
} from "../../../services/services";
import {
  projectsListAPI,
  propertiesListAPI,
  updatePlotAPI,
  addPlotAPI,
  plotDetailsByIDAPI,
  states,
} from "../../../common/constants";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Select,
  Form,
  Input,
  CollapseProps,
} from "antd";
import AdminHeader from "../shared/header";

function AddPlot() {
  const [form] = Form.useForm();
  const [projectList, setProjectList] = useState([]);
  const [rawProperties, setRawProperties] = useState([]);
  const [propertiesList, setPropertiesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [plotID, setPlotId] = useState("");
  const [loading, setLoading] = useState(true);

  async function fetchProjects() {
    const res = await getData(projectsListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = getProjectDropdownList(response.data);
      console.log(listItems);
      setProjectList(listItems);
      setIsLoading(false);
    }
  }

  async function fetchProperties() {
    const res = await getData(propertiesListAPI);
    const response = await res.json();
    if (response.status === "success") {
      setRawProperties(response.data);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchProjects();
    fetchProperties();
  }, []);

  async function fetchPlotData(plot_id) {
    const res = await getDataWithPayload(plotDetailsByIDAPI, {
      plot_id: plot_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      setData(response.data[0]);
      setLoading(false);
      const d = response.data[0];
      form.setFieldValue("project_id", d.project_id);
      form.setFieldValue("property_id", d.property_id);
      form.setFieldValue("plot_status", d.plot_status);
      form.setFieldValue("pass_book_number", d.pass_book_number);
      form.setFieldValue("registration_number", d.registration_number);

      form.setFieldValue("plot_number", d.plot_number);
      //form.setFieldValue("plot_pic",d.plot_pic);
      form.setFieldValue("square_yards", d.square_yards);
      // form.setFieldValue("unit_number",d.unit_number);
      form.setFieldValue("price_per_cent", d.price_per_cent);
      form.setFieldValue("price_per_square_yard", d.price_per_square_yard);
      form.setFieldValue("prce_per_one_cent", d.prce_per_one_cent);
      form.setFieldValue("total_price", d.total_price);

      form.setFieldValue("address1", d.address1);
      form.setFieldValue("address2", d.address2);
      form.setFieldValue("city", d.city);
      form.setFieldValue("state", d.state);
      form.setFieldValue("latitude", d.latitude);
      form.setFieldValue("longtitude", d.longtitude);
      form.setFieldValue("booking_amount", d.booking_amount);
      form.setFieldValue("agreement_amount", d.agreement_amount);
      form.setFieldValue("registration_amount", d.registration_amount);
      form.setFieldValue("emi_booking_amount", d.emi_booking_amount);
      form.setFieldValue("emi_registration_amount", d.emi_registration_amount);
      form.setFieldValue("number_of_emis", d.number_of_emis);
      form.setFieldValue("emi_amount", d.emi_amount);
      form.setFieldValue("total_emi_amount", d.total_emi_amount);
      form.setFieldValue("plan_b_total_emi_amount", d.plan_b_total_emi_amount);
    }
  }

  useEffect(() => {
    const plot_id = searchParams.get("plot_id");
    setPlotId(plot_id);
    if (plot_id) fetchPlotData(plot_id);
    else setLoading(false);
  }, [searchParams]);

  const statusList = [
    { value: "Sold", label: <span>Sold</span> },
    { value: "Booked", label: <span>Booked</span> },
    { value: "Available", label: <span>Available</span> },
  ];
  const items: CollapseProps[] = [
    {
      key: "1",
      label: "Plot Details",
      children: <Plot />,
    },
    {
      key: "2",
      label: "Location",
      children: <Location />,
    },
    {
      key: "3",
      label: "Price Details",
      children: <Price />,
    },

    {
      key: "4",
      label: "Plan A Price Details",
      children: <PlanA />,
    },
    {
      key: "5",
      label: "Plan B Price Details",
      children: <PlanB />,
    },
  ];
  const handleEmichange = (e) => {
    let regAmount = form.getFieldValue("emi_registration_amount");
    let noOfEmis = form.getFieldValue("number_of_emis");
    let emiAmount = form.getFieldValue("emi_amount");
    if (regAmount > 0 && noOfEmis > 0 && emiAmount > 0) {
      let totalEmiAmount = Number(noOfEmis) * Number(emiAmount);
      let planBTotalAmount = Number(totalEmiAmount) + Number(regAmount);
      form.setFieldValue("total_emi_amount", totalEmiAmount);
      form.setFieldValue("plan_b_total_emi_amount", planBTotalAmount);
    }
  };

  const handleOnPriceChange = (e) => {
    let bookingPrice = form.getFieldValue("booking_amount");
    let agreementPrice = form.getFieldValue("agreement_amount");
    let totalPrice = form.getFieldValue("total_price");
    if(bookingPrice > 0 && agreementPrice > 0 && totalPrice > 0) {
      form.setFieldValue("registration_amount", 0);
      form.setFieldValue("registration_amount", Number(totalPrice)-(Number(agreementPrice)+Number(bookingPrice)));
    } else {
      form.setFieldValue("registration_amount", 0);
    }
  }
  const handleOnCentsChange = (e) => {
    let cents = form.getFieldValue("cents");
  if(cents > 0) {
    form.setFieldValue("square_yards", cents*48.4);
  }
}
  const handleOnChange = (e) => {
    let squareYards = form.getFieldValue("square_yards");
    if(squareYards > 0) {
      form.setFieldValue("cents", squareYards/48.4);
    }
  
    let pricePerTenCents = form.getFieldValue("price_per_cent");
    if (!squareYards || !pricePerTenCents) return;
    let pricePerCent = Number(pricePerTenCents) / 10;
    form.setFieldValue("prce_per_one_cent", pricePerCent.toFixed(2));
    let pricePerSquareYard = Number(pricePerTenCents) / 484;
    form.setFieldValue("price_per_square_yard", pricePerSquareYard.toFixed(2));
    let totalPrice = pricePerSquareYard * squareYards;
    form.setFieldValue("total_price", totalPrice.toFixed(2));
   
  };

  const handlePlanBBookingPriceChange = (e) => {
    let emiBookingPrice = form.getFieldValue('emi_booking_amount');
    form.setFieldValue('emi_amount', emiBookingPrice);
  }

  const handleProjectChange = (value) => {
    
     var data = rawProperties.filter((item) => { return item.project_id == value} );
    const propertyList = getPropertiesDropdownList(data);
    form.setFieldValue("property_id", "");
    setPropertiesList(propertyList);
  }

  function Plot() {
    return (
      <>
        <Form.Item
          className="cell"
          label="Project"
          name="project_id"
          rules={[
            {
              required: true,
              message: "Please input your Project!",
            },
          ]}
        >
          <Select options={projectList} onSelect={(value) => handleProjectChange(value)}></Select>
        </Form.Item>
        <Form.Item
          className="cell"
          label="Property"
          name="property_id"
          rules={[
            {
              required: true,
              message: "Please input your property!",
            },
          ]}
        >
          <Select options={propertiesList}></Select>
        </Form.Item>

        <Form.Item
          label="Plot Status"
          name="plot_status"
          rules={[
            {
              required: true,
              message: "Please input your status!",
            },
          ]}
        >
          <Select options={statusList}></Select>
        </Form.Item>
        <Form.Item label="Pass book number" name="pass_book_number">
          <Input />
        </Form.Item>
        <Form.Item label="Registration number" name="registration_number">
          <Input />
        </Form.Item>
        {/* <Form.Item label="Plot Picture" name="plot_pic">
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Upload Plot area</Button>
          </Upload>
        </Form.Item> */}
      </>
    );
  }

  function Price() {
    return (
      <>
        <Form.Item
          label="Plot Start #"
          name="plot_start_number"
          rules={[
            {
              required: true,
              message: "Please input your plot!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Plot End #"
          name="plot_end_number"
          rules={[
            {
              required: true,
              message: "Please input your plot!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Square Yards" name="square_yards" rules={[
            {
              required: true,
              message: "Please input square yards!",
            },
          ]}> 
          <Input onChange={handleOnChange} />
        </Form.Item>
        <Form.Item label="Cents" name="cents" rules={[
            {
              required: true,
              message: "Please input cents!",
            },
          ]}>
          <Input onChange={handleOnCentsChange} />
        </Form.Item>
        <Form.Item
          label="Price per 10 cents"
          name="price_per_cent"
          rules={[
            {
              required: true,
              message: "Please input your price!",
            },
          ]}
        >
          <Input onChange={handleOnChange} />
        </Form.Item>
        <Form.Item label="Price per Square Yard" name="price_per_square_yard">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Price per cent"
          name="prce_per_one_cent"
          rules={[
            {
              required: true,
              message: "Please input your price!",
            },
          ]}
        >
          <Input disabled={true} />
        </Form.Item>

        <Form.Item
          label="Total Price"
          name="total_price"
          rules={[
            {
              required: true,
              message: "Please input your total price!",
            },
          ]}
        >
          <Input disabled={true} />
        </Form.Item>
      </>
    );
  }
  function PlanA() {
    return (
      <>
        <Form.Item
          label="Booking Amount"
          name="booking_amount"
          rules={[
            {
              required: true,
              message: "Enter Booking Amount",
            },
          ]}
        >
          <Input onChange={handleOnPriceChange}/>
        </Form.Item>

        <Form.Item
          label="Agreement Amount"
          name="agreement_amount"
          rules={[
            {
              required: true,
              message: "Enter Agreement Amount",
            },
          ]}
        >
          <Input onChange={handleOnPriceChange}/>
        </Form.Item>

        <Form.Item
          label="Registration Amount"
          name="registration_amount"
          rules={[
            {
              required: true,
              message: "Enter Registration Amount",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </>
    );
  }
  function PlanB() {
    return (
      <>
        <Form.Item
          label="Booking Amount"
          name="emi_booking_amount"
          rules={[
            {
              required: true,
              message: "Enter Booking Amount",
            },
          ]}
        >
          <Input onChange={handlePlanBBookingPriceChange}/>
        </Form.Item>

        <Form.Item
          label="Registration Amount"
          name="emi_registration_amount"
          rules={[
            {
              required: true,
              message: "Enter Registration Amount",
            },
          ]}
        >
          <Input onChange={handleEmichange} />
        </Form.Item>

        <Form.Item
          label="Number of EMI's"
          name="number_of_emis"
          rules={[
            {
              required: true,
              message: "Enter Registration Amount",
            },
          ]}
        >
          <Input onChange={handleEmichange} />
        </Form.Item>
        <Form.Item
          label="EMI amount"
          name="emi_amount"
          rules={[
            {
              required: true,
              message: "Enter EMI Amount",
            },
          ]}
        >
          <Input onChange={handleEmichange} />
        </Form.Item>
        <Form.Item
          label="Total EMI amount"
          name="total_emi_amount"
          rules={[
            {
              required: true,
              message: "Enter Total EMI Amount",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="plan B Total Price"
          name="plan_b_total_emi_amount"
          rules={[
            {
              required: true,
              message: "Enter plan B total amount",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </>
    );
  }
  function Location() {
    return (
      <>
        <Form.Item label="Address 1" name="address1">
          <Input />
        </Form.Item>
        <Form.Item label="Address 2" name="address2">
          <Input />
        </Form.Item>
        <Form.Item label="City" name="city">
          <Input />
        </Form.Item>
        <Form.Item label="State" name="state">
          <Select
            options={states}
            style={{ width: "100%" }}
            placeholder="Please select"
          />
        </Form.Item>
        <Form.Item label="Longitude" name="longtitude">
          <Input />
        </Form.Item>
        <Form.Item label="Latitude" name="latitude">
          <Input />
        </Form.Item>
      </>
    );
  }

  //Form submit method
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      setHasError(false);
      const payload = {
        ...data,
        ...values,
        plot_id: data.plot_id,
      };

      let endpoint;
      if (data.plot_id) {
        endpoint = updatePlotAPI;
      } else {
        endpoint = addPlotAPI;
      }
      const response = await addData(endpoint, payload);
      const result = await response.json();
      if (result && result.status === "success") {
        setIsLoading(false);
        window.location.replace("/adminplotlist");
        return;
      } else {
        setHasError(true);
        setIsLoading(false);
        console.log("api failure");
      }
    } catch {
      setIsLoading(false);
      setHasError(true);
      console.log("Catch block api error!");
    }
  };

  const handleCancel = () => {
    window.location.replace("/adminplotlist");
    return;
  };
  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };

  if (!plotID || (plotID && data.project_id))
    return (
      <div className="admin ">
        <div className="flex flex-row fullheight">
          <div className="admin-dashboard">
            <AdminHeader></AdminHeader>

            <div className="bodycontainer emp-view">
              <div className="flex flex-column  accordion">
                <Form
                  className="form"
                  layout="vertical"
                  onFinish={handleSubmit}
                  onFinishFailed={handleErrors}
                  form={form}
                >
                  <div className="flex flex-row vcenter around-gutter">
                    <h1 className="flex-row cell no-gutter">Add Plot </h1>
                  </div>
                  <div
                    className="scrollform"
                    style={{ height: "calc(100vh - 298px)" }}
                  >
                    {/* <Collapse items={items} expandable="true" /> */}
                    <h3 className="sectitle">Project Details</h3>
                    <Plot />,<h3 className="sectitle">Locataion</h3>
                    <Location />
                    <h3 className="sectitle"> Plot Price</h3>
                    <Price />,<h3 className="sectitle">Plan A Price Details</h3>
                    <PlanA />
                    <h3 className="sectitle">Plan B Price Details</h3>
                    <PlanB />
                  </div>
                  <div className="stickybuttonbg">
                    <Button
                      htmlType="submit"
                      type="secondary"
                      className="ant-btn-secondary self-end right-gutter"
                    >
                      Save
                    </Button>
                    <Button type="primary" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AddPlot;
