import React, { useState, useEffect } from "react";
import { Space, Table } from "antd";
import { Link } from "react-router-dom";
import AdminHeader from "../shared/header";
import { getData, deleteData } from "../../../services/services";
import {
  adminProjectsListAPI,
  deleteProjectAPI,
} from "../../../common/constants";

function ProjectList() {
  const [projects, setPojects] = useState([]);

  async function fetchProjects() {
    const res = await getData(adminProjectsListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setPojects(listItems);
    }
  }

  async function deleteProject(project_id) {
    const res = await deleteData(deleteProjectAPI, { project_id: project_id });
    const response = await res.json();
    if (response.status === "success") {
      fetchProjects();
    } else {
      alert("Error in Deleting employee");
    }
  }

  useEffect(() => {
    fetchProjects();
  }, []);

  const biscolumns = [
    // {
    //   title: "Id",
    //   dataIndex: "project_id",
    // },
    {
      title: "project",
      dataIndex: "project_name",
    },

    {
      title: "Project Status",
      dataIndex: "status",
    },
    {
      title: "Actions",
      dataIndex: "operation",
      render: (_, record: { title: React.Key }) =>
        projects.length >= 1 ? (
          <Space size="middle">
            <span
              className="material-symbols-outlined gridbtn"
              onClick={() => handleEdit(record)}
            >
              Edit{" "}
            </span>
            {/* <span
              className="material-symbols-outlined gridbtn"
              onClick={() => handleDelete(record)}
            >
              toggle_on
            </span> */}
          </Space>
        ) : null,
    },
  ];

  const handleEdit = (item) => {
    const projectId = item.project_id;
    window.location.replace("/addproject?project_id=" + projectId);
    return;
  };
  const handleDelete = (item) => {
    deleteProject(item.project_id);
  };

  return (
    <div className="admin ">
      <div className="flex flex-row fullheight">
        <div className="admin-dashboard">
          <AdminHeader></AdminHeader>

          <div className="flex-row cell">
            <div className="bodycontainer">
              <div className="flex flex-row vcenter bottom-gutter-20">
                <h1 className="right-gutter-40 cell">All Projects</h1>
                <Link
                  to="/addproject"
                  className="ant-btn-primary bottom-gutter"
                >
                  Add Project
                </Link>
              </div>
              <div className="flex-column top">
               
                <Table
                  columns={biscolumns}
                  dataSource={projects}
                  className="fullwidth tablescroll1"  pagination={false} scroll={{y : 400}} 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectList;
