
import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import CustomerHeader from '../shared/header';
import CustomerPropertyList from '../../shared/customerpropertylist';
import PaymentGrid from '../../shared/paymentgrid';
import PlotRegistration from '../../shared/plotregistration';
function CustomerDashboard() {

    const [customerId, setCustomerId] = useState("");
   
    useEffect(() => {
        const customerDetails = JSON.parse(localStorage.getItem('customerDetails'));
        if (!customerDetails || !customerDetails.name) {
            window.location.replace("/customerlogin");
            return;
          }
        setCustomerId(customerDetails.customer_id);
      }, []);
      if(customerId)
    return (
        <div className='customer'>
            <div className="customerdashboard flex flex-row cell">
                <CustomerHeader></CustomerHeader>
                <div className='customerbody '>
                    <h1>DASHBOARD</h1>
                    <h2>Payments & Dues</h2>
                    <PaymentGrid  customerId={customerId}  showButton={true}/>
                </div>
            </div>

        </div>
    );
}

export default CustomerDashboard;
