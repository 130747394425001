import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Spin } from "antd";
import EmployeeHeader from "../../employee/shared/header";
import logo from '../../../assets/images/logoonly.png';
import logotitle from '../../../assets/images/logotitle.png';
import loginimg from '../../../assets/images/loginagent.png';
import { Link } from "react-router-dom";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import { Login } from "../../../services/services";
import { loginAPI, errorMessage } from "../../../common/constants";
import { useSelector, useDispatch } from 'react-redux';
import { updateLoginDetails } from '../../../redux/employee/employeeSlice';
import PurchaseScheme from "../../shared/purchasescheme";

function EmployeeLogin() {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  //Form submit method
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      setHasError(false);
      if (values?.remember) {
        delete values.remember;
      }
      const response = await Login(loginAPI, values);
      const result = await response.json();
      if (result && result.userstatus === 0) {
        const userDetails = {
          id: result.data.id,
          name: result.data.name,
          emp_id: result.data.emp_id,
          profile_pic: result.data.profile_pic,
        };
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        dispatch(updateLoginDetails(userDetails));
        setIsLoading(false);
        window.location.replace("/employeeresetpassword");
        return;
      } else if (result && result.userstatus === 1) {
        const userDetails = {
          id: result.data.id,
          name: result.data.name,
          emp_id: result.data.emp_id,
          profile_pic: result.data.profile_pic,
        };
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        // dispatch(updateLoginDetails(userDetails)); 
        setIsLoading(false);
        window.location.replace("/employeedashboard");
        return;
      } else {
        setHasError(true);
        setIsLoading(false);
        console.log("api failure");
      }
    } catch {
      setIsLoading(false);
      setHasError(true);
      console.log("Catch block api error!");
    }
  };

  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };

  return (
    <div className="employee emplogin">
      {/* <div className="adminalert">
        <div className="alertcont">
        <h2><b>Please wait...for Approval</b></h2>
        <Spin />
        <p>An email has been sent to the admin. Please wait until the admin approves your request with the proper designation</p>
       
        </div>
      </div> */}
      <div className="customerlogin flex flex-row cell mob-flex-row">
        <div className="logindetails cell">
        <div className="logostyles">
          <figure className="logo" ><img src={logo} /></figure>
          <figure className="logotitle"><img src={logotitle} /></figure>
        </div>
        <h1>Welcome to Agent Application</h1>
        <img src={loginimg} />
        </div>
        <div className="flex flex-column cell scroll vcenter">
       
        <Form
          className="form"
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleErrors}
        >

          <h2 className="flex ">
          <span class="material-symbols-outlined size-50">
              account_circle
            </span>
            {" "}
            <span className=" text-center">Login</span>
          </h2>
          <Form.Item
            label="Username"
            name="emp_id"

            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <div className="flex flex-row vcenter bottom-gutter mob-flex-row">
            <Form.Item
              className="no-gutter"
              name="remember"
              valuePropName="checked"
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <span className="cell text-right">
              If you are not having account,{" "}
              <Link to="/employeeregistration">Sign Up</Link>
            </span>
          </div>
          {hasError && <ErrorComponent errorMessage={errorMessage} />}
          {isLoading && <Spin />}
          <Form.Item className="flex middle">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        </div>
      </div>

      {/* <EmployeeFooter></EmployeeFooter> */}
    </div>
  );
}

export default EmployeeLogin;
