import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logoonly.png";
import logotitle from "../../../assets/images/logotitle.png";
import { Button } from "antd";

function EmployeeHeader() {
  const [username, setusername] = useState("");
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (!userDetails || !userDetails.name) {
      window.location.replace("/employeelogin");
      return;
    }
    const name = userDetails.name;
    setusername(name);
    //setusername("admin");
  }, []);

  const handleLogout = () => {
    localStorage.setItem("userDetails", "{}");
    window.location.replace("/employeelogin");
    return;
  };
  return (
    <header>
      <div className="logostyles flow-row">
        <figure className="logo">
          <img src={logo} />
        </figure>
        <figure className="logotitle">
          <img src={logotitle} />
        </figure>
      </div>
      <span className="cell"></span>
      <div className="loggedinuser">
        <Button className="iconbutton">
          <span class="material-symbols-outlined size-72">person</span>
        </Button>
        {username}
        <Button className="iconbutton left-gutter-20" onClick={handleLogout}>
          <span class="material-symbols-outlined size-72">Logout</span>
        </Button>
      </div>
    </header>
  );
}

export default EmployeeHeader;
