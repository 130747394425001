import React, { useState, useEffect } from "react";
import { Table, Form, Select, Button, Modal } from "antd";
import { getDataWithPayload, getData, getProjectDropdownList, getPropertiesDropdownList, getPlotDropdownList, formatPrice } from "../../services/services";
import { ordersListAPI, adminOrderListAPI } from "../../common/constants";
import { Link } from "react-router-dom";
import EmiTable from "./emitable";
function PaymentGrid(props) {
  const { customerId = "", showButton=false } = props;
  const [orders, setOrders] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [projects, setProjects] = useState([]);
  const [properties, setProperties] = useState([]);
  const [plots, setPlots] = useState([]);
  const [rawOrders, setRawOrders] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [propertyName, setPropertyName] = useState("");

  async function fetchOrderssList() {
    let res;
    if (customerId) {
      res = await getDataWithPayload(ordersListAPI, {
        customer_id: customerId,
      });
    } else {
      res = await getData(adminOrderListAPI);
    }
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setOrders(listItems);
      setRawOrders(listItems);
      const projectList = getProjectDropdownList(listItems);
      setProjects(projectList);
    }
  }
  useEffect(() => {
    fetchOrderssList();
  }, []);

  const paycolumns = [
    {

      title: "Order id",
      dataIndex: "id",
    },
    {
      title: "Customer",
      dataIndex: "customerName",
    },
    
    {
      title: "project",
      dataIndex: "project_name",
    },
    {
      title: "Property",
      dataIndex: "property_name",
    },
    {
      title: "Plot #",
      dataIndex: "plot_number",
    },
    {
      title: "Square Yards (Cents)",
      dataIndex: "square_yards",

    },
    

    {
      title: "Agent ID",
      dataIndex: "emp_id",
    },
    {
      title: "Plan",
      dataIndex: "plan_type",
    },
    {
      title: "Total Plot Amount",
      dataIndex: "total_amount",
      render: (value) => formatPrice(value),
      align: 'right'
    },
    {
      title: "Paid Amount",
      dataIndex: "total_amount_paid",      
      render: (value) => formatPrice(value),
      align: 'right'
    },
    {
      title: "Due Amount",
      dataIndex: "total_amount_due",      
      render: (value) => formatPrice(value),
      align: 'right'
    },

    {
      title: "Plot Status",
      dataIndex: "plot_status",
      key: "project",
      render: (text) => <span>{text}</span>,
    }
  ];


  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (orderId) => {
    setOrderId(orderId);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = async (values) => {
    try {
      var data = rawOrders.filter((item) => { return item.project_id == values.project && item.prop_id == values.property && item.plot_number == values.plot} );
      setOrders(data);
      
    } catch {
      console.log("Catch block api error!");
    }
  };

  const handleProjectChange = (project) => {
    var data = rawOrders.filter((item) => { return item.project_id == project} );
    const propertyList = getPropertiesDropdownList(data);
    setProjectName(project)
    setProperties(propertyList);
  }
  const handlePropertyChange = (property) => {
    var data = rawOrders.filter((item) => { return item.project_id == projectName && item.prop_id == property} );
    const plotsList = getPlotDropdownList(data);
    setPropertyName(property)
    setPlots(plotsList);
  }

  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };

    return (
      <>
        <Form  className="form flex-row nowidth  horizontal-form filterbg mob-flex-row" onFinish={handleSubmit}
                onFinishFailed={handleErrors}>
        <Form.Item
          className="cell "
          label="Project"
          name="project"
          rules={[
            {
              required: true,
              message: "Please select project",
            },
          ]}
        >
          <Select options={projects} onSelect={(value) => handleProjectChange(value)}></Select>
        </Form.Item>
        <Form.Item
          className="cell "
          label="Property"
          name="property"
          rules={[
            {
              required: true,
              message: "Please select project",
            },
          ]}
        >
          <Select options={properties} onSelect={(value) => handlePropertyChange(value)}></Select>
        </Form.Item>
        
         <Form.Item
          className="cell"
          label="Plot"
          name="plot"
          rules={[
            {
              required: true,
              message: "Please property",
            },
          ]}
        >
          <Select options={plots}></Select>
        </Form.Item>
        <Button htmlType="submit" type="primary">Search</Button>
      </Form>
    {orders.length > 0 ? (
      <Table
      columns={paycolumns}
      rowKey="id" scroll={{ x: 250 }}
      pagination={false}
      expandable={{
        expandedRowRender: (record) => (
          <>
            {(record.plan_type == "planA" || record.plan_type == "Other") && (
              <table className="statictable">
                <tr>
                  <th>Paid date</th>
                  <th>Amount type</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                <tbody>
                <tr>
                  <td>{record.booking_amount_paid_date}</td>
                  <td>Booking amount</td>
                  <td className="price"> {formatPrice(record.booking_amount)}</td>
                  <td>{record.booking_amount_paid == 1 ? "Paid" : "Due"}</td>
                  <td align="center"><Link to={'/invoicereport?paymentType=Booking&orderId=' + record.id} target="_blank" className="iconbutton"><span
                    className="material-symbols-outlined">
                    request_quote
                  </span></Link></td>
                </tr>
                {(record.plan_type == "planA" || (record.plan_type == "Other" && record.agreement_amount_paid == 1)) && (
                <tr>
                  <td>{record.agreement_amount_paid_date}</td>
                  <td>Agreement amount</td>
                  <td className="price"> {formatPrice(record.agreement_amount)}</td>
                  <td>{record.agreement_amount_paid == 1 ? "Paid" : "Due"}</td>
                  {record.agreement_amount_paid == 1 ? (
                     <td align="center"><Link to={'/invoicereport?paymentType=Agreement&orderId=' + record.id} target="_blank" className="iconbutton"><span
                     className="material-symbols-outlined">
                     request_quote
                   </span></Link></td>
                  ) : (
                    <td align="center">NA</td>
                  )
                  }
                </tr>
                )}
                {(record.plan_type == "planA" || (record.plan_type == "Other" && record.registration_amount_paid == 1)) && (
                <tr>
                  <td>{record.registration_amount_paid_date}</td>
                  <td>Registration amount</td>
                  <td className="price">{formatPrice(record.registration_amount)}</td>
                  <td>{record.registration_amount_paid == 1 ? "Paid" : "Due"}</td>
                  {record.registration_amount_paid == 1 ? (
                    <td align="center"><Link to={'/invoicereport?paymentType=Registration&orderId=' + record.id} target="_blank" className="iconbutton"><span
                    className="material-symbols-outlined">
                    request_quote
                  </span></Link></td>
                  ) : (
                    <td align="center">NA</td>
                  )}
                  
                </tr>
                )}
                {(showButton && record.plot_status !== 'Sold') && (
                <tr><td colspan="5" align="right"><Link className="ant-btn-primary top-gutter bottom-gutter" to={'/paymentview?orderId=' + record.id}>Pay due amount</Link></td></tr>
                )}
                </tbody>
              </table>
            )}
            {record.plan_type == "planB" && (
              <table className="statictable">
                <tr>
                  <th>Paid emis</th>
                  <th>Number of Pending emis</th>
                  <th>Emi amount</th>
                  <th>Paid amount</th>
                  <th>Due amount</th>
                  {/* <th>Action</th> */}
                </tr>
                <tbody>
                <tr>
                  <td>{record.paid_emis}</td>
                  <td>{record.pending_emis}</td>
                  <td className="price">{formatPrice(record.emi_amount)}</td>
                  <td className="price">{formatPrice(record.total_amount_paid)}</td>
                  <td className="price">{formatPrice(record.total_amount_due)}</td>
                </tr>
                                <tr><td colSpan="6" align="right"><Button className="ant-btn-primary"  onClick={() => showModal(record.id)}>Emi Table</Button>
                                {(showButton && record.plot_status != 'Sold') && ( 
                                <Link className="ant-btn-primary" to={'/paymentview?orderId=' + record.id}>Pay due amount</Link>
                                )}
                                </td></tr>
                </tbody>
              </table>
            )}
          </>
        ),

      }}
      dataSource={orders}
    />
    ) : (
      <span> No Data found...!</span>
    )}
        
          <Modal title="EMI Table" width={"80vw"} open={isModalOpen} footer={null} onCancel={handleCancel}>
           <EmiTable orderId={orderId}></EmiTable>
            </Modal>
      </>
    );
}

export default PaymentGrid;
