import React, { useState, useEffect } from "react";
import logo from "../../../assets/images/logoonly.png";
import logotitle from "../../../assets/images/logotitle.png";
import { Button } from "antd";
import { Link } from "react-router-dom";
import AdminNav from "../shared/nav";
function AdminHeader() {
  const [username, setusername] = useState("");
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("adminUserDetails"));
    if (!userDetails || !userDetails.name) {
      window.location.replace("/adminlogin");
      return;
    }
    const name = userDetails.name;
    setusername(name);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("adminUserDetails");
    window.location.replace("/adminlogin");
    return;
  }
  return (
    <div className="flex flex-row adminstickypositionheader">
      <header>
        <div className="logostyles flow-row">
          <figure className="logo">
            <img src={logo} />
          </figure>
          <figure className="logotitle">
            <img src={logotitle} />
          </figure>
        </div>
        <AdminNav></AdminNav>
        <div className="loggedinuser">
        <Link  className="ant-btn-primary self-end right-gutter-40" to='/adminplotlist'>Purchase New Plot</Link>
          <Button className="iconbutton left-gutter">
            <span class="material-symbols-outlined size-72">person</span>
          </Button>
          {username}
          <Button className="iconbutton left-gutter-20" >
          <span class="material-symbols-outlined size-72" onClick={handleLogout}>Logout</span>
        </Button>
        </div>
      </header>
     
    </div>
  );
}

export default AdminHeader;
