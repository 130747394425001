import React, { useState, useEffect } from "react";

import AdminHeader from "../shared/header";

import EmployeeNav from "../shared/nav";


import PlotInDetails from "../../shared/plotindetails";

function PropertyDetails() {


  return (
    <div className="employee emp-dashboard flex flex-column cell">
      <AdminHeader></AdminHeader>
      <div className="flex-row cell">
        <EmployeeNav></EmployeeNav>
        <div className="bodycontainer propertydetails">
          <h2>
            Property Details<small>Property / Hyderabad Location</small>
          </h2>
            <PlotInDetails></PlotInDetails>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetails;
