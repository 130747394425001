import React, { useState, useEffect } from "react";
import { getDataWithPayload, formatPrice } from "../../services/services";
import { emiListAPI } from "../../common/constants";
import { Link } from "react-router-dom";
function EmiTable(props) {
  const { orderId = "" } = props;
  const [emis, setEmis] = useState([]);
  async function fetchEmiList() {
    let res;
    if (orderId) {
      res = await getDataWithPayload(emiListAPI, {
        orderid: orderId,
      });
      const response = await res.json();
      if (response.status === "success") {
        const listItems = response.data;
        setEmis(listItems);
      }
    }
  }
  useEffect(() => {
    fetchEmiList();
  }, []);

  const Customerlist = () => {
    const customerdet = (
      <div className="flex flex-row">
        <article class="employer">
          <h2>Customer Details</h2>
          <figure>
            <img src="https://omah.dexignzone.com/codeigniter/demo/public/assets/images/users/pic1.jpg" />
            <figcaption>{emis[0].customerName}</figcaption>
          </figure>
          <dl>
            <dt>Email</dt>
            <dd>{emis[0].customerEmail}</dd>
          </dl>
          <dl>
            <dt>Phone</dt>
            <dd>{emis[0].customerMobile}</dd>
          </dl>

          <dl>
            <dt>Address</dt>
            <dd>
              {emis[0].customerAddress1}, {emis[0].customerAddress2},{" "}
              {emis[0].customerCity}, {emis[0].customerState}
            </dd>
          </dl>
        </article>
        <article class="employer">
          <h2>Plot Details</h2>
          <dl>
            <dt>Project</dt>
            <dd>{emis[0].project_name}</dd>
          </dl>
          <dl>
            <dt>Property</dt>
            <dd>{emis[0].property_name}</dd>
          </dl>
          <dl>
            <dt>Plot #</dt>
            <dd>{emis[0].plot_number}</dd>
          </dl>
          <dl>
            <dt>Plot size</dt>
            <dd>{emis[0].square_yards} Sq. Yards</dd>
          </dl>
          <dl>
            <dt>Address</dt>
            <dd>
              {emis[0].customerAddress1}, {emis[0].customerAddress2},{" "}
              {emis[0].customerCity}, {emis[0].customerState}
            </dd>
          </dl>
        </article>
        <article class="employer">
          <h2>Price Details</h2>
          <dl>
            <dt>Plan</dt>
            <dd>{emis[0].plan_type}</dd>
          </dl>
          <dl>
            <dt>Plot Total Price</dt>
            <dd>{formatPrice(emis[0].total_amount)}</dd>
          </dl>
          <dl>
            <dt>Number of Emi's</dt>
            <dd>{emis[0].number_of_emis}</dd>
          </dl>
          <dl>
            <dt>Plot #</dt>
            <dd>{emis[0].plot_number}</dd>
          </dl>
          <dl>
            <dt>Address</dt>
            <dd>
              {emis[0].customerAddress1}, {emis[0].customerAddress2},{" "}
              {emis[0].customerCity}, {emis[0].customerState}
            </dd>
          </dl>
        </article>
      </div>
    );
    console.log(JSON.stringify(emis));
    return customerdet;
  };

  const EmiList = () => {
    const listItems = emis.map((Item) => (
      <tr>
        <td>{Item.index}</td>
        <td>{Item.order_id}</td>
        <td>{Item.created_at}</td>
        <td>{Item.payment_mode}</td>
        <td>{Item.payment_type}</td>
        <td className="price"> {formatPrice(Item.amount_paid)}</td>
        {/* <td className="price"> {formatPrice(Item.gst)}</td> */}
        <td className="price">Paid</td>
        <td align="center">
          <Link
            to={"/invoicereport?receipt_id=" + Item.p_id}
            target="_blank"
            className="iconbutton"
          >
            <span className="material-symbols-outlined">request_quote</span>
          </Link>
        </td>
      </tr>
    ));
    return listItems;
  };
  if (emis.length > 0)
    return (
      <>
        <div className="employee">
          <Customerlist />
        </div>
        <table className="statictable no-gutter">
          <tr>
            <th>Sequence #</th>
            <th>Order ID</th>
            <th>Emi Date</th>
            <th>Payment Mode</th>
            <th>Payment Type</th>
            <th>Emi amount</th>
            {/* <th>Gst</th> */}
            <th>Emi Status</th>
            <th>Invoice</th>
          </tr>
          <EmiList />
        </table>
      </>
    );
}

export default EmiTable;
