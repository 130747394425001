import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Spin } from "antd";
import logo from "../../../assets/images/logoonly.png";
import logotitle from "../../../assets/images/logotitle.png";
import { Link } from "react-router-dom";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import { Login } from "../../../services/services";
import { customerLoginAPI, errorMessage } from "../../../common/constants";
import PurchaseScheme from "../../shared/purchasescheme";

function CustomerLogin() {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Form submit method
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      setHasError(false);
      if (values?.remember) {
        delete values.remember;
      }
      const response = await Login(customerLoginAPI, values);
      const result = await response.json();
      if (result && result.userstatus === 0) {
        const userDetails = {
          id: result.data.customer_id,
          name: result.data.name,
          customer_id: result.data.cust_id,
        };
        localStorage.setItem("customerDetails", JSON.stringify(userDetails));
        setIsLoading(false);
        window.location.replace("/customerresetpassword");
        return;
      } else if (result && result.userstatus === 1) {
        const userDetails = {
          id: result.data.customer_id,
          name: result.data.name,
          customer_id: result.data.cust_id,
        };
        localStorage.setItem("customerDetails", JSON.stringify(userDetails));
        // dispatch(updateLoginDetails(userDetails));
        setIsLoading(false);
        window.location.replace("/CustomerDashboard?customer_id="+result.data.cust_id);
        return;
      } else {
        setHasError(true);
        setIsLoading(false);
        console.log("api failure");
      }
    } catch {
      setIsLoading(false);
      setHasError(true);
      console.log("Catch block api error!");
    }
  };

  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };

  return (
    <div className="customer customerloginform">
      <div className=" customerlogin flex flex-column cell">
     
        <div className="flex flex-row boxshadow">
        <div class="customerlogdet">
        <div className="logostyles">
          <figure className="logo">
            <img src={logo} />
          </figure>
          <figure className="logotitle">
            <img src={logotitle} />
          </figure>
        </div>
       <PurchaseScheme></PurchaseScheme>
        </div>
        <div className="form-cont">
        <Form
          className="form"
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleErrors}
        >
          <h1>
            {" "}
            <span className="cell text-center">Login</span>
          </h1>
          <Form.Item
            label="Username"
            name="cust_id"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div className="flex flex-row vcenter">
            <Form.Item
             
              name="remember"
              valuePropName="checked"
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <span className="cell text-right">
              If you are not having account,{" "}
              <Link to="/customerregistration">Sign Up</Link>
            </span>
          </div>
          <Form.Item className="flex middle">
            {hasError && <ErrorComponent errorMessage={errorMessage} />}
            {isLoading && <Spin />}
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerLogin;
