import React, { useState, useEffect } from "react";
import { Space, Table, Tag, Modal } from "antd";
import logo from "../../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import AdminHeader from "../shared/header";
import AdminFooter from "../shared/footer";
import AdminNav from "../shared/nav";

import { getData,deleteData } from "../../../services/services";
import { customersAdminListAPI, deleteCustomerAPI } from "../../../common/constants";
import CustomerPropertyList from "../../shared/customerpropertylist";

function CustomerList() {
  const [customers, setCustomers] = useState([]);
  async function fetchCustomersList() {
    const res = await getData(customersAdminListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setCustomers(listItems);
    }
  }
  useEffect(() => {
    fetchCustomersList();
  }, []);
  const columns = [
    {
      title: "Customer ID",
      dataIndex: "cust_id",
    },
    {
      title: "Introducer ID",
      dataIndex: "employee_id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Occupation",
      dataIndex: "occupation",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record: { title: React.Key }) =>
        customers.length >= 1 ? (
          <Space size="middle">
             <span className="material-symbols-outlined "  onClick={() => handleViewCustomer(record)}>visibility</span>
            {/* <span
              className="material-symbols-outlined gridbtn"
              onClick={() => handleDelete(record)}
            >
              delete
            </span> */}
          </Space>
        ) : null,
    },
  ];

  const handleViewCustomer = (item) => {
    const customer_id = item.customer_id;
    window.location.replace("/editcustomerdetails?customer_id=" + customer_id);
    return;
  }
  const handleDelete = (item) => {
    deleteCustomer(item.customer_id);
  };

  async function deleteCustomer(customer_id) {
    const res = await deleteData(deleteCustomerAPI, { customer_id: customer_id });
    const response = await res.json();
    if (response.status === "success") {
      fetchCustomersList();
    } else {
      alert("Error in Deleting customer");
    }
  }
  return (
    <div className="admin ">
      <div className="flex flex-row fullheight">
        <div className="admin-dashboard">
          <AdminHeader></AdminHeader>

          <div className="flex-row cell">
            <div className="bodycontainer">
              <div className="flex flex-row vcenter">
                <h1 className="cell">All Customers</h1>
              </div>

                  {/* <CustomerPropertyList></CustomerPropertyList> */}
                  <Table  columns={columns}   dataSource={customers}  pagination={false} scroll={{y : 400}} className="tablescroll1"/>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerList;
