
import React, { useState } from 'react';
import { Space, Table, Tag, Modal, Button, Checkbox, Form, Input, Spin, Select   } from 'antd';

import logo from '../../../assets/images/Logo.png';
import { Link} from "react-router-dom";
import AdminHeader from '../shared/header';
import AdminFooter from '../shared/footer';
import AdminNav from '../shared/nav';
import PurchaseScheme from '../../shared/purchasescheme';
// import EditEmployee from '../modal/editemployee';

function EstateList() {
  const biscolumns = [
    {
      title: 'Project',
      dataIndex: 'Project',
      key: 'location',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Property',
      dataIndex: 'Property',
      key: 'project',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Plot #',
      dataIndex: 'plotnumber',
      key: 'project',
      render: (text) => <span>{text}</span>,
    },
    {
    title: 'Square Yards (Cents)',
    dataIndex: 'squareyards',
    key: 'project',
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'project',
    render: (text) => <span>{text}</span>,
  },
 
  {
    title: 'Property Status',
    dataIndex: 'paystatus',
    key: 'project',
    render: (text) => <span>{text}</span>,
  },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <a  onClick={showModal}><span class="material-symbols-outlined ">
  //                     edit
  //                 </span> </a>
  //       <a><span class="material-symbols-outlined ">
  //                     delete
  //                 </span></a>
  //     </Space>
  //   ),
  // },
  ];
  const bisdata = [
  

  ];
      const [isModalOpen, setIsModalOpen] = useState(false);

      const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleOk = () => {
        setIsModalOpen(false);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
      };
    return (
      <div className="admin ">
      <div className='flex flex-row fullheight'>

      <div className='admin-dashboard'>
        <AdminHeader></AdminHeader>
        
        <div className='flex-row cell'>
      
        <div className='bodycontainer ' >
        <PurchaseScheme></PurchaseScheme>
          <div className="flex flex-row vcenter bottom-gutter-20">
          <h1 className="cell">All Estate List</h1>
         

          </div>
          <Form className='form flex-row nowidth vcenter horizontal-form filterbg'>
                        <Form.Item
                            className="cell "
                            label="Project"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select project",
                                },
                            ]}
                        >
                            <Select></Select>
                        </Form.Item>
                        <Form.Item
                            className="cell"
                            label="Property"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please property",
                                },
                            ]}
                        >
                            <Select></Select>
                        </Form.Item>
                        <Button type='primary'>Search</Button>
                    </Form>
          <Table columns={biscolumns} dataSource={bisdata} />
        </div>
        </div>
        </div>
        </div>
       
    </div>
        
    );
}

export default EstateList;
