import CurrencyFormat from 'react-currency-format';

export async function Login(apiName, payload) {
  return await fetch(apiName, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export async function addData(apiName, payload) {
  return await fetch(apiName, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getData(apiName) {
  return fetch(apiName, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getDataWithPayload(apiName, payload) {
  return await fetch(apiName, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateData(apiName, payload) {
  return await fetch(apiName, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function addOrupdateDataWithImage(apiName, payload) {
  return await fetch(apiName, {
    method: "POST",
    body: payload,
  });
}

export async function deleteData(apiName, payload) {
  return await fetch(apiName, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getEmployeeDropdownList(data) {
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    const item = {
      value: data[i].emp_id,
      label:   data[i].emp_id + "- " + data[i].name + data[i].last_name,
    };
    arr.push(item);
  }
  return arr;
}

export function getProjectDropdownList(data) {
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    const item = {
      value: data[i].project_id,
      label: data[i].project_name,
    };
    arr.push(item);
  }
  const uniqueArray = [...new Map(arr.map(item => [item['value'], item])).values()];
  return uniqueArray;
}

export function getPropertiesDropdownList(data) {
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    const item = {
      value: data[i].property_id || data[i].prop_id,
      label: data[i].property_name,
    };
    arr.push(item);
  }
  const uniqueArray = [...new Map(arr.map(item => [item['value'], item])).values()];
  return uniqueArray;
}

export function getPlotDropdownList(data) {
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    const item = {
      value: data[i].plot_number,
      label: data[i].plot_number,
    };
    arr.push(item);
  }
  return arr;
}

export function getCustomerDropdownList(data) {
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    const item = {
      value: data[i].cust_id,
      label: data[i].name,
    };
    arr.push(item);
  }
  return arr;
}

export function getRolesDropdownList(data) {
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    const item = {
      value: data[i].role_title,
      label: data[i].role_title,
    };
    arr.push(item);
  }
  return arr;
}

export function getEmpDropdownList(data) {
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    for(let j=0;j<data[i].receiptData.length;j++) {
      for(let k=0;k<data[i].receiptData[j].commissionData.length;k++) {
         const item = {
          value: data[i].receiptData[j].commissionData[k].emp_id,
          label: data[i].receiptData[j].commissionData[k].employeeName,
        }
        arr.push(item);
    }
  }
  }
  const uniqueArray = [...new Map(arr.map(item => [item['value'], item])).values()];
  return uniqueArray;
}

export const formatPrice = (price) => {
  var updatedPrice = price ? Number(price) : 0;
  return <CurrencyFormat value={updatedPrice} displayType={'text'} thousandSeparator={true} prefix={'₹ '} renderText={value => <div>{value}</div>} />
}
