
import React, { useState, useEffect } from 'react';
import { Space, Table,} from 'antd';
import { Link} from "react-router-dom";
import AdminHeader from '../shared/header';
import { getData, deleteData } from "../../../services/services";
import {
  adminRolesListAPI,
  deleteRoleAPI,
} from "../../../common/constants";

function RoleMaintenance() {
  const [roles, setRoles] = useState([]);
  async function fetchRoles() {
    const res = await getData(adminRolesListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setRoles(listItems);
    }
  }

  async function deleteRole(role_id) {
    const res = await deleteData(deleteRoleAPI, { id: role_id });
    const response = await res.json();
    if (response.status === "success") {
      fetchRoles();
    } else {
      alert("Error in Deleting employee");
    }
  }

  useEffect(() => {
    fetchRoles();
  }, []);
  const biscolumns = [
    // {
    //   title: 'Id',
    //   dataIndex: 'role_id',
    // },
    {
    title: 'Designation',
    dataIndex: 'role_title',
  },
  {
    title: 'Plan A Percentage',
    dataIndex: 'role_percentage_plan_a',
    render: (value) => value+"%",
  },
  {
    title: 'Plan B Percentage',
    dataIndex: 'role_percentage_plan_b',
    render: (value) => value+"%",
  },
  
  // {
  //   title: 'Status',
  //   dataIndex: 'status',
  // },
  {
    title: "operation",
    dataIndex: "operation",
    render: (_, record: { title: React.Key }) =>
      roles.length >= 1 ? (
        <Space size="middle">
          <span
            className="material-symbols-outlined gridbtn"
            onClick={() => handleEdit(record)}
          >
            Edit{" "}
          </span>
          {/* <span
            className="material-symbols-outlined gridbtn"
            onClick={() => handleDelete(record)}
          >
            toggle_on
          </span> */}
        </Space>
      ) : null,
  },
  ];
  
  const handleEdit = (item) => {
    const roleId = item.id;
    window.location.replace("/updaterole?role_id=" + roleId);
    return;
  };
  const handleDelete = (item) => {
    deleteRole(item.id);
  };
    return (
      <div className="admin ">
      <div className='flex flex-row fullheight'>

      <div className='admin-dashboard'>
        <AdminHeader></AdminHeader>
        
        <div className='flex-row cell'>
      
        <div className='bodycontainer ' >
          <div className="flex flex-row vcenter bottom-gutter-20">
          <h1 className="cell">Role & Percentage Maintenance
      
                                    
          </h1>
          <Link to="/UpdateRole" className="right-gutter ant-btn-primary">Add Role & Percentage</Link>
          </div>

          <Table columns={biscolumns} dataSource={roles}  pagination={false} scroll={{y : 400}} className="tablescroll1"/>
        </div>
        </div>
        </div>
        </div>
       
    </div>
        
    );
}

export default RoleMaintenance;
