import React, { useState, useEffect } from "react";
import {
    Button,
    Checkbox,
    Select,
    Form,
    Input,
    DatePicker,
    message,
    CollapseProps,
    Steps,
    theme,
    Upload,
    Radio, Space, RadioChangeEvent
} from "antd";
import dayjs from "dayjs";
import { Collapse } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { updateData, getDataWithPayload } from "../../services/services";
import {
    updateCustomerAPI,
    registerCustomerAPI,
    states,
    customerDetailsByIDAPI,
} from "../../common/constants";
import { useSearchParams } from "react-router-dom";
function ChoosePlan() {

    const [value, setValue] = useState();
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    return (

        <Form
            className="form"
            layout="vertical"

        >
            <Form.Item
                label="Select Plan"
                name="plan"
                rules={[
                    {
                        required: true,
                        message: "Please input your name!",
                    },
                ]}
            >
                <Radio.Group onChange={onChange} value={value}>
                    <Space direction="vertical">
                        <Radio value={1}>Outrate

                        </Radio>
                        <Radio value={2}>Installment

                        </Radio>

                    </Space>
                </Radio.Group>
            </Form.Item>
            {value === 1 ? (
                <>
                <Form.Item
                    label="Total Amount"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Total Amount",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Booking Amount"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Booking Amount",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Booking Due Amount Date"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Booking Due Amount Date",
                        },
                    ]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    label="Agreement Amount"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Agreement Amount",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Agreement Due Amount Date"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Agreement Due Amount Date",
                        },
                    ]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    label="Registration Amount"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Registration Amount",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Registration Due Amount Date"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Registration Due Amount Date",
                        },
                    ]}
                >
                    <DatePicker />
                </Form.Item>
                </>
            ) : null}
            {value === 2 ? (
                <>
                <Form.Item
                    label="Total Amount"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Total Amount",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Booking Amount"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Booking Amount",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Booking Due Amount Date"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Booking Due Amount Date",
                        },
                    ]}
                >
                    <DatePicker />
                </Form.Item>
               
                <Form.Item
                    label="Registration Amount"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Registration Amount",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Registration Due Amount Date"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Registration Due Amount Date",
                        },
                    ]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    label="Number of EMI's"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Registration Amount",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="EMI Amount"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Registration Amount",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="EMI Due Date"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter Registration Amount",
                        },
                    ]}
                >
                    <DatePicker />
                </Form.Item>
                </>
            ) : null}



            <div className="flex flex-row top-gutter-20 end">
                <Button
                    type="secondary"
                    htmlType="submit"
                    className="right-gutter bottom-gutter-20 self-end"
                >
                    Save
                </Button>
                {/* <Button
                                    type="primary"
                                    className="right-gutter bottom-gutter-20 self-end"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button> */}
            </div>
        </Form>

    );
}

export default ChoosePlan;
