
import React, { useState } from 'react';
import { Button } from "antd";
import logo from "../../assets/images/logoonly.png";
import logotitle from "../../assets/images/logotitle.png";
import { Preview, print } from 'react-html2pdf';
function EmpAssociationReport() {

    return (
        <>
            <div className='flex end top-gutter-20 right-gutter'>
                <Button type="primary" onClick={() => print('a', 'jsx-template')}> Download Assocation Application</Button>
            </div>
            <Preview id={'jsx-template'} >
                <div className='invoicereport'>
                    <table width='100%' height="100%" cellpadding='0' cellspacing='0' border='0' align='center' style={{ padding: "10px" }}>
                        <tr>
                            <table width="100%" >
                                <tr>
                                    <td align='center'>
                                        <table align='center' width="100%" >
                                            <tr>
                                                <td width="100">  <img src={logo} style={{ width: "80px" }} /></td>
                                                <td><img src={logotitle} style={{ width: "250px" }} /></td>

                                            </tr>
                                        </table>
                                    </td>


                                </tr>
                                <tr bgcolor="#074223">
                                    <table width='100%' height="100%">
                                        <tr>
                                        <td width="30"></td>
                                        <td>  <font style={{ fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold" }} color="#fff">Association Form</font></td>
                                            <td align='center' width="130">
                                                <p><b style={{ color: "#fff", fontSize: "16px", fontFamily: "sans-serif" }}>Designation : <br /></b></p>

                                            </td>
                                            <td width="130"> <p style={{ color: "#fff", fontSize: "12px", fontFamily: "sans-serif" }}>CEO</p></td>

                                            <td align='center' width="150">
                                                <p><b style={{ color: "#fff", fontSize: "16px", fontFamily: "sans-serif" }}>Identification #: <br /></b></p>

                                            </td>
                                            <td width="130"> <p style={{ color: "#fff", fontSize: "12px", fontFamily: "sans-serif" }}>HYD/002</p></td>
                                        </tr>
                                    </table>
                                </tr>
                                <tr>
                                    <td align='right'>
                                        <table width="300" cellpadding="0" cellspacing="0" border="0" bgcolor="#f8f6ee">
                                            <tr height="15"><td></td></tr>

                                            <tr height="15"><td></td></tr>
                                            <tr><td style={{ paddingLeft: "40px" }}><font style={{ fontSize: "11px", fontFamily: "sans-serif", textTransform: "uppercase" }} color="#074223"><b>EMPLOYEE INFORMATION</b></font></td></tr>
                                            <tr><td style={{ paddingLeft: "40px" }}><font style={{ fontSize: "10px", fontFamily: "sans-serif" }} color="#454546">Mr. Jeevan Kumar<br />Address 1 Place<br />Address2, city, staty</font></td></tr>

                                            <tr height="15"><td></td></tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr><td ><font style={{ fontSize: "18px", fontWeight:"100", fontFamily: "sans-serif", textTransform: "uppercase" }} color="#074223"><b>OTHER INFORMATION</b></font></td></tr>
                                <tr height="30">
                                    <td >

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" align='left'>
                                        <table width='600' height="100%" cellpadding='10' cellspacing='0' border='0'>
                                        
                                          
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>S/o W/o D/o:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>Nominee:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>DOB:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>Occupation:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>Relatioinship:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                                                                  
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>Mobile:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>Email:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>Bank Account #:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>IFSC Code:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>Bank Name:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "12px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>Branch Name:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "11px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>Adhar Card #:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                            <tr>
                                                <td width="200" style={{ color: "#666", fontSize: "11px", fontFamily: "sans-serif", textTransform: "uppercase",  }}>PAN:</td>
                                                <td style={{ color: "#000", fontWeight: "bold", fontSize: "13px", fontFamily: "sans-serif",  }}>Plan A (outrate)</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr height="5">
                                    <td >

                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: "#000", fontSize: "12px", fontFamily: "sans-serif", padding:"20", backgroundColor:"#f9f9f9" }}><br />I have seen the site and felt happy to note the contents, after reading and getting rechecked of reading about site details. On thorough understanding upon discussed with my family members, I wish to opt to take a unit at your project in the form of easy equal installments or outrate basis. Today I am paying the required amount as per the cost of the unit of the project<br /></td>
                                </tr>
                                <tr height="120">
                                    <td >

                                    </td>
                                </tr>
                                <tr>
                                    <td align='right' style={{ color: "#000", fontWeight: "bold", fontSize: "14px", fontFamily: "sans-serif", }} >
                                        Signature of the Customer
                                    </td>
                                </tr>
                            </table>

                        </tr>
                        <tr >
                    <td>
                    <table align='center' width="100%" >
                                   
                                    <tr bgcolor="#074223">
                                        <td colSpan="2" align='center'>
                                            <p><b style={{color:"#fff", fontSize: "16px", fontFamily: "sans-serif"}}>SRI LAKSHMI AGRO FARMS <br /></b></p>
                                            <p  style={{color:"#fff",  fontSize: "12px", fontFamily: "sans-serif"}}>Plot 6 & 11 # 4-7-102/6, 4th Floor, Veeranag Towers, Sai Enclave,Metro Pillal No. 972, Habsiguda, <br />Hyderabad - 500007</p>
                                        </td>
                                    </tr>
                                </table>
                    </td>
                </tr>
                    </table>

                </div>
            </Preview>
        </>
    );
}

export default EmpAssociationReport;
