
import React, { useState, useEffect } from 'react';
import {Button} from "antd";
import logo from "../../assets/images/logoonly.png";
import logotitle from "../../assets/images/logotitle.png";
import { Preview, print } from 'react-html2pdf';
import { useSearchParams } from "react-router-dom";
import { getDataWithPayload, formatPrice } from "../../services/services";
import { emiDetailsByIDAPI, emiListAPI } from "../../common/constants";
function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}/${date}/${year}`;
  }
function InvoiceReport() {
    const [currentDate, setCurrentDate] = useState(getDate());
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState({});
    const [receiptId, setReceiptId] = useState("");

    async function fetchReceipt(id) {
        const receipt_id = searchParams.get("receipt_id");
        const res = await getDataWithPayload(emiDetailsByIDAPI, {
          receipt_id: receipt_id,
        });
    //    const res = await getDataWithPayload(emiListAPI, {
    //         orderid: "4",
    //       });

        const response = await res.json();
        if (response.status === "success") {
          setData(response.data[0]);
        }
      }

      async function fetchEmiList() {
        const orderId = searchParams.get("orderId");
        const paymentType = searchParams.get("paymentType");
         const res = await getDataWithPayload(emiListAPI, {
            orderid: orderId,
          });
          const response = await res.json();
          if (response.status === "success") {
            const listItems = response.data;
            var data = listItems.filter((item) => { return item.payment_type == paymentType} );
            setData(data[0]);
          }
      }

    useEffect(() => {
        const receipt_id = searchParams.get("receipt_id");
        const orderId = searchParams.get("orderId");
        if (receipt_id) {
          fetchReceipt(receiptId);
        }
        if(orderId) {
            fetchEmiList();
        }
      }, []);
    
    return (
        <>
        <div className='flex end top-gutter-20 right-gutter'>
        <Button type="primary" onClick={()=>print('a', 'jsx-template')}> Download Invoice</Button>
        </div>
        <Preview id={'jsx-template'} >
        <div className='invoicereport'>
            <table width='100%' height="100%" cellpadding='0' cellspacing='0' border='0' align='center' style={{padding:"20px"}}>
                <tr>
                    <table width="100%" >
                        <tr>
                            <td align='center'>
                                <table align='center' width="100%" >
                                    <tr>
                                        <td width="100">  <img src={logo} style={{ width: "80px" }} /></td>
                                        <td><img src={logotitle} style={{ width: "250px" }} /></td>

                                    </tr>
                                </table>
                            </td>

                            <td align="right" valign='bottom' >
                                <table align='center' width="100%" bgcolor="#f8f6ee" cellPadding={10} >
                                    <tr>
                                        <td align='right'>
                                            <font style={{ fontSize: "12px", fontFamily: "sans-serif", }} color="#000000"><b>Date:{currentDate}</b></font>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>

                </tr>
                <tr bgcolor="#074223">
                    <td>
                        <table bgcolor="#f5f9f9" width="100%" cellpadding="10" cellspacing="0" border="0">
                            <tbody><tr>
                                <td width="30"></td>
                                <td>  <font style={{ fontSize: "18px", fontWeight:"bold", fontFamily: "sans-serif", textTransform: "uppercase" }} color="#074223">Receipt</font></td>
                                <td width="300">
                                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                        <tbody><tr>
                                            <td>
                                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                    <tbody><tr>
                                                        <td><font style={{ fontSize: "11px", fontFamily: "sans-serif", }} color="#333">Purchased Date: </font></td>
                                                    </tr>
                                                        <tr>
                                                            <td><font style={{ fontSize: "14px", fontWeight:"bold", fontFamily: "sans-serif", }} color="#074223">{data.created_at}</font></td>
                                                        </tr>
                                                    </tbody></table>
                                            </td>
                                            <td>
                                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                    <tbody><tr>
                                                        <td><font style={{ fontSize: "11px", fontFamily: "sans-serif" }} color="#666">Receipt # </font></td>
                                                    </tr>
                                                        <tr>
                                                            <td><font style={{ fontSize: "14px",fontWeight:"bold", fontFamily: "sans-serif", textTransform: "uppercase" }} color="#074223">{data.p_id}</font></td>
                                                        </tr>
                                                    </tbody></table>
                                            </td>
                                            <td width="40"></td>
                                        </tr>
                                        </tbody></table>
                                </td>
                            </tr>
                            </tbody></table>

                    </td>

                </tr>
                <tr>
                    <td align='right'>
                        <table width="100%">
                            <tr>
                                <td>
                                <p><b style={{color:"#e31e25", fontSize: "16px", fontFamily: "sans-serif"}}>SRI LAKSHMI AGRO FARMS <br /></b></p>
                                            <p  style={{color:"#333",  fontSize: "12px", fontFamily: "sans-serif"}}>Plot 6 & 11 # 4-7-102/6,<br />4th Floor,<br /> Veeranag Towers,<br /> Sai Enclave,Metro Pillal No. 972,<br /> Habsiguda, <br />Hyderabad - 500007</p>
                                </td>
                                <td width="300">
                                <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#f8f6ee">
                            <tr height="15"><td></td></tr>
                            <tr><td align="center"><font style={{ fontSize: "11px", fontFamily: "sans-serif" }} color="#4e4e4e">Amount Paid</font></td></tr>
                            <tr><td align="center"><font style={{ fontSize: "20px", fontFamily: "sans-serif", textTransform: "uppercase" }} color="#074223"><b> {formatPrice(data.amount_paid)}</b></font> </td></tr>
                            <tr height="15"><td></td></tr>
                            <tr><td><hr style={{height:"1px", width:"100%", backgroundColor:"#074223", border:"none"}} /></td></tr>
                            <tr height="15"><td></td></tr>
                            <tr><td style={{paddingLeft:"40px"}}><font style={{ fontSize: "11px", fontFamily: "sans-serif", textTransform: "uppercase" }} color="#074223"><b>BILLING INFORMATION</b></font></td></tr>
                            <tr><td style={{paddingLeft:"40px"}}><font style={{ fontSize: "11px", fontFamily: "sans-serif" }} color="#454546">{data.customerName}<br />{data.customerAddress1}, {data.customerAddress2}, {data.customerCity}, {data.customerState}, <br />Mobile:<b>{data.customerMobile},<br /> Email: {data.customerEmail}</b></font></td></tr>

                            <tr height="15"><td></td></tr>
                        </table>
                                </td>
                            </tr>
                        </table>
                       
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width='100%' cellpadding='5' cellspacing='0' style={{border:"1px solid #074223"}}>
                            <tr>
                                <td bgcolor="#ebf6f0" style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold", color:"#074223"}}>Customer Name</td>
                                <td bgcolor="#ebf6f0" style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold", color:"#074223"}} >Project</td>
                                <td bgcolor="#ebf6f0" style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold", color:"#074223"}}>Property</td>
                                <td bgcolor="#ebf6f0" style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold", color:"#074223"}}>Plot #</td>
                                <td bgcolor="#ebf6f0" style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold", color:"#074223"}}>Square yards</td>
                                <td bgcolor="#ebf6f0" style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold", color:"#074223"}}>Payment Mode</td>
                                <td bgcolor="#ebf6f0" style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold", color:"#074223"}}>Payment Type</td>
                                <td bgcolor="#ebf6f0" style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold", color:"#074223"}}>Price</td>
                                <td bgcolor="#ebf6f0" style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold", color:"#074223"}}>Total Amount Paid(till today)</td>
                                <td bgcolor="#ebf6f0" style={{borderBottom:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", textTransform: "uppercase", fontWeight:"bold", color:"#074223" }}>Total Amount Due(till today)</td>
                            </tr>
                            <tr>
                                <td style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif",  fontWeight:"bold", color:"#333"}}>{data.customerName}</td>
                                <td style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif",  fontWeight:"bold", color:"#333"}} >{data.project_name} </td>
                                <td style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif",  fontWeight:"bold", color:"#333"}}>{data.property_name}</td>
                                <td style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif", fontWeight:"bold", color:"#333"}}>{data.plot_number}</td>
                                <td style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "10px", fontFamily: "sans-serif",  fontWeight:"bold", color:"#333"}}>{data.square_yards}</td>
                                <td style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223",textAlign:"right", fontSize: "10px", fontFamily: "sans-serif", fontWeight:"bold", color:"#333" }}> {data.payment_mode}</td>
                                <td style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223",textAlign:"right", fontSize: "10px", fontFamily: "sans-serif", fontWeight:"bold", color:"#333" }}>{data.payment_type}</td>
                                <td style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223",textAlign:"right", fontSize: "10px", fontFamily: "sans-serif", fontWeight:"bold", color:"#333" }}>  {formatPrice(data.total_amount)}</td>
                                <td style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223",textAlign:"right", fontSize: "10px", fontFamily: "sans-serif", fontWeight:"bold", color:"#333" }}>  {formatPrice(data.total_amount_paid)}</td>
                                <td style={{borderBottom:"1px solid #074223", textAlign:"right", fontSize: "10px", fontFamily: "sans-serif", fontWeight:"bold", color:"#333" }}>  {formatPrice(data.total_amount_due)}</td>
                            </tr>
    
                            <tr>
                               
                                <td colSpan="9" align='right' bgcolor="#074223" style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "14px", fontFamily: "sans-serif",  fontWeight:"bold", color:"#Fff"}}>Total</td>
                                <td  align='right' bgcolor="#074223" style={{borderBottom:"1px solid #074223", textAlign:"right", fontSize: "14px", fontFamily: "sans-serif", fontWeight:"bold", color:"#fff" }}> {formatPrice(data.total_amount)}</td>
                            </tr>
                            <tr>
                               
                               <td colSpan="9" align='right'  style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "14px", fontFamily: "sans-serif",  fontWeight:"bold", color:"#074223"}}>Amount Paid</td>
                               <td  align='right'  style={{borderBottom:"1px solid #074223", textAlign:"right", fontSize: "14px", fontFamily: "sans-serif", fontWeight:"bold", color:"#074223" }}>  {formatPrice(data.amount_paid)}</td>
                           </tr>
                           {/* <tr>
                               
                           <td colSpan="9" align='right'  style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "14px", fontFamily: "sans-serif",  fontWeight:"bold", color:"#074223"}}>GST.</td>
                           <td  align='right'  style={{borderBottom:"1px solid #074223", textAlign:"right", fontSize: "14px", fontFamily: "sans-serif", fontWeight:"bold", color:"#074223" }}> {formatPrice(data.gst)}</td>
                           </tr> */}
                           <tr>
                               
                               <td colSpan="9" align='right'  style={{borderBottom:"1px solid #074223", borderRight:"1px solid #074223", fontSize: "14px", fontFamily: "sans-serif",  fontWeight:"bold", color:"#fe0b0b"}}>Total Amount paid</td>
                               <td  align='right'  style={{borderBottom:"1px solid #074223", textAlign:"right", fontSize: "14px", fontFamily: "sans-serif", fontWeight:"bold", color:"#fe0b0b" }}>  {formatPrice(Number(data.amount_paid))}</td>
                           </tr>
                        </table>
                    </td>
                </tr>
                <tr height="50"></tr>
                <tr >
                    <td>
                    <table align='center' width="100%" >
                                   
                                    <tr bgcolor="#074223">
                                        <td colSpan="2" align='center'>
                                            <p><b style={{color:"#fff", fontSize: "16px", fontFamily: "sans-serif"}}>SRI LAKSHMI AGRO FARMS <br /></b></p>
                                            <p  style={{color:"#fff",  fontSize: "12px", fontFamily: "sans-serif"}}>Plot 6 & 11 # 4-7-102/6, 4th Floor, Veeranag Towers, Sai Enclave,Metro Pillal No. 972, Habsiguda, <br />Hyderabad - 500007</p>
                                        </td>
                                    </tr>
                                </table>
                    </td>
                </tr>
            </table>

        </div>
        </Preview>
        </>
    );
}

export default InvoiceReport;
