import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Tag,
  Modal,
  Button,
  Checkbox,
  Form,
  Input,
  Spin,
  Select,
} from "antd";
import logo from "../../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import AdminHeader from "../shared/header";
import AdminFooter from "../shared/footer";
import AdminNav from "../shared/nav";
import PurchaseScheme from "../../shared/purchasescheme";
// import EditEmployee from '../modal/editemployee';
import { getData, deleteData, getProjectDropdownList } from "../../../services/services";
import {
  adminPropertiesListAPI,
  deletePropertyAPI,
  projectsListAPI,
} from "../../../common/constants";

function AdminPropertyList() {
  const [properties, setProperties] = useState([]);
  const [rawProperties, setRawProperties] = useState([]);
  const [projectList, setProjectList] = useState([]);

  async function fetchProperties() {
    const res = await getData(adminPropertiesListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setProperties(listItems);
      setRawProperties(listItems);
    }
  }

  async function deleteProperty(property_id) {
    const res = await deleteData(deletePropertyAPI, {
      property_id: property_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      fetchProperties();
    } else {
      alert("Error in Deleting employee");
    }
  }
  async function fetchProjects() {
    const res = await getData(projectsListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = getProjectDropdownList(response.data);
      setProjectList(listItems);
    }
  }

  useEffect(() => {
    fetchProperties();
    fetchProjects();
  }, []);

  const biscolumns = [
    {
      title: "project",
      dataIndex: "project_name",
    },
    {
      title: "Property",
      dataIndex: "property_name",
    },

    {
      title: "property Status",
      dataIndex: "status",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record: { title: React.Key }) =>
        properties.length >= 1 ? (
          <Space size="middle">
            <span
              className="material-symbols-outlined gridbtn"
              onClick={() => handleEdit(record)}
            >
              Edit{" "}
            </span>
            {/* <span
              className="material-symbols-outlined gridbtn"
              onClick={() => handleDelete(record)}
            >
              toggle_on
            </span> */}
          </Space>
        ) : null,
    },
  ];

  const handleEdit = (item) => {
    const propertyId = item.property_id;
    window.location.replace("/adminaddproperty?property_id=" + propertyId);
    return;
  };
  const handleDelete = (item) => {
    deleteProperty(item.property_id);
  };
 const handleProjectChange = (e) => {
  var items = rawProperties.filter((item) => { return item.project_id == e} );
  setProperties(items);
 }

  return (
    <div className="admin ">
      <div className="flex flex-row fullheight">
        <div className="admin-dashboard">
          <AdminHeader></AdminHeader>

          <div className="flex-row cell">
            <div className="bodycontainer">
              <div className="flex flex-row vcenter bottom-gutter-20">
                <h1 className="right-gutter-40 cell">All Properties</h1>
                <Form className="form flex-row nowidth vcenter horizontal-form filterbg cell">
                  <Form.Item
                    className="cell "
                    label="Project"
                    name="project"
                    rules={[
                      {
                        required: true,
                        message: "Please select project",
                      },
                    ]}
                  >
                    <Select options={projectList} onChange={handleProjectChange}></Select>
                  </Form.Item>

                  {/* <Button type="primary" htmlType="submit">Search</Button> */}
                </Form>
              </div>
              <div className="flex-column top">
                <Link
                  to="/adminaddproperty"
                  className="ant-btn-primary bottom-gutter"
                >
                  Add Property
                </Link>
                <Table
                  columns={biscolumns}
                  dataSource={properties}
                  className="fullwidth tablescroll1"  pagination={false} scroll={{y : 400}} 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPropertyList;
