
import React, {useState} from 'react';
import { Space, Table, Tag, Button, Modal } from 'antd';

import AdminHeader from '../shared/header';
import { Link} from "react-router-dom";
import EmployeeNav from '../shared/nav';
import AddEditPayment from './AddPaymenthistory';
import PaymentGrid from '../../shared/paymentgrid';


function PaymentHistory() {

  
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
        <div className="employee emp-dashboard flex flex-column cell">
            <AdminHeader></AdminHeader>
            <div className='flex-row cell'>
                <EmployeeNav></EmployeeNav>
                <div className='bodycontainer'>
                    <h2>Payment</h2>

                    <div className='flex-column top top-gutter-20 f'>
                 
                        <article className='whitearticle cell fullwidth'>
                          
                           <PaymentGrid></PaymentGrid>
                        </article>
                    </div>

                </div>
            </div>
            {/* <Modal title="Add/Edit Payments" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
               <AddEditPayment></AddEditPayment>
            </Modal> */}
        </div>

    );
}

export default PaymentHistory;
