import React, { useState, useEffect } from "react";
import { Space, Table, Tag } from "antd";

import AdminHeader from "../shared/header";
import { Button, Checkbox, Form, Input, Spin } from "antd";
import EmployeeNav from "../shared/nav";
import { Link } from "react-router-dom";
import { getDataWithPayload } from "../../../services/services";
import { employeesListByRefAPI } from "../../../common/constants";

function EmployeeEmpoyer() {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);

  async function fetchEmployeesList() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (!userDetails || !userDetails.name) {
      window.location.replace("/employeelogin");
      return;
    }
    const res = await getDataWithPayload(employeesListByRefAPI, {
      reference: userDetails.emp_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setEmployees(listItems);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchEmployeesList();
  }, []);

  const TeamGrid = () => {
    const listItems = employees.map((employee) => (
      <article className="employer">
        <figure>
          <img src={'https://srilakshmiagrofarms.co.in/webapplication/uploads/employees/'+employee.profile_pic} />
          <figcaption>
            {employee.name} {employee.last_name}{" "}
            <small>{employee.designation}</small>
          </figcaption>
        </figure>
        <dl>
          <dt>Email</dt>
          <dd>{employee.email}</dd>
        </dl>
        <dl>
          <dt>Phone</dt>
          <dd>{employee.mobile_number}</dd>
        </dl>
        <dl>
          <dt>Role</dt>
          <dd>{employee.designation}</dd>
        </dl>
        <dl>
          <dt>Location</dt>
          <dd>{employee.location}</dd>
        </dl>
        <div className="flex vcenter top-gutter-20 middle">
          <Link
            to={"/employerdetails?emp_id=" + employee.emp_id.replace("/", "_")}
            class="ant-btn-primary"
          >
            View / Edit Details
          </Link>
        </div>
      </article>
    ));
    return <div className="flex-row wrap mob-flex-row">{listItems}</div>;
  };
  if (loading) return <span> loading...</span>;
  if (!loading)
    return (
      <div className="employee emp-dashboard flex flex-column cell">
        <AdminHeader></AdminHeader>
        <div className="flex-row cell">
          <EmployeeNav></EmployeeNav>
          <div className="bodycontainer">
            <h2>Team Members</h2>
            <div className="flex-row">
              <div className="flex-column bottom-gutter-20 cell right-gutter-40">
                <article className="totalproperties cell">
                  <h3>
                    <span class="material-symbols-outlined size-32">badge</span>
                    Total Team
                  </h3>
                  <strong>{employees.length}</strong>
                </article>
                <div className="flex-row wrap">
                  <TeamGrid />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default EmployeeEmpoyer;
