import React, { useEffect, useState } from "react";
import { Space, Table, Tag } from "antd";
import AdminHeader from "../shared/header";
import EmployeeNav from "../shared/nav";
import { Link } from "react-router-dom";
import { getDataWithPayload, getData, formatPrice } from "../../../services/services";
import { employeesListByRefAPI, plotsAPI, employeesPaymentHistoryAPI, employeeTeamBusinessAPI } from "../../../common/constants";
import TeamBusiness from "../../shared/teambusiness";

import EmpCommissions from "../../shared/comissions";

function EmployeeDashboard() {
  const [employees, setEmployees] = useState([]);
  const [plots, setPlots] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [bookedPlots, setBookedPlots] = useState([]);
  const [availablePlots, setAvailablePlots] = useState([]);
  const [employeePaymentHistory, setEmployeePaymentHistory] = useState([]);
  const [teeamBussinessHistory, setTeamBussinessHistory] = useState([]);

  async function fetchEmployeesListByRef() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const res = await getDataWithPayload(employeesPaymentHistoryAPI, {
      emp_id: userDetails.emp_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setEmployeePaymentHistory(listItems);
    }
  }
  async function fetchEmployeePaymentHistory() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const res = await getDataWithPayload(employeesListByRefAPI, {
      reference: userDetails.emp_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setEmployees(listItems);
    }
  }

  async function fetchTeamPaymentHistory() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const res = await getDataWithPayload(employeeTeamBusinessAPI, {
      reference: userDetails.emp_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.teambusiness;
      setTeamBussinessHistory(listItems);
    }
  }

  async function fetchPlots() {
    const res = await getData(plotsAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setPlots(listItems);
      var bplots = listItems.filter((item) => { return item.plot_status == 'Booked' });
      var availablePlots = listItems.filter((item) => { return item.plot_status == 'Available' });
      console.log(JSON.stringify(listItems))
      setBookedPlots(bplots);
      setAvailablePlots(availablePlots);
    }
  }

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("userDetails"));
    if (!loginData || !loginData.name) {
      window.location.replace("/employeelogin");
      return;
    }
    setUserDetails(loginData);
    fetchEmployeesListByRef();
    fetchEmployeePaymentHistory();
    fetchPlots();
    fetchTeamPaymentHistory();
  }, []);


  const paycolumns = [
    {
      title: "Date",
      dataIndex: "created_at",
      
    },
    {
      title: "customer Name",
      dataIndex: "customerName",
    },
    {
      title: "project Name",
      dataIndex: "project_name",
    },
    {
      title: "Property Name",
      dataIndex: "property_name"
    },
    {
      title: "Plot Number",
      dataIndex: "plot_number"
    },
    {
      title: "Plot Price",
      dataIndex: "total_amount",
      render: (value) => formatPrice(value),
      align: 'right'
    },
    {
      title: "Due Price",
      dataIndex: "total_amount_due",
      render: (value) => formatPrice(value),
      align: 'right'
    },
    {
      title: "Plan",
      dataIndex: "plan_type"
    },
    {
      title: "Status",
      dataIndex: "plot_status",
    },
  ];
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "emp_id",
      dataIndex: "emp_id",
      key: "emp_id",
      // render: (text) => <span>{text}</span>,
    },
    {
      title: "First Name",
      dataIndex: "name",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "DOB ",
      dataIndex: "dateofbirth",
      key: "dateofbirth",
    },
    {
      title: "Email ",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Designation ",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "mobile ",
      dataIndex: "mobile_number",
    },
    {
      title: "Work Location ",
      dataIndex: "location",
    },
    {
      title: "Introducer ID",
      dataIndex: "reference",
    },
    {
      title: "Status",
      dataIndex: "delete_status",
    },
  ];
  const TeamGrid = () => {
    const listItems = employees.map((employee) => (
      <article className="employer">
        <figure>
        <img src={'https://srilakshmiagrofarms.co.in/webapplication/uploads/employees/'+employee.profile_pic} alt="" />
          <figcaption>
            {employee.name} {employee.last_name}{" "}
            <small>{employee.designation}</small>
          </figcaption>
        </figure>
        <dl>
          <dt>Email</dt>
          <dd>{employee.email}</dd>
        </dl>
        <dl>
          <dt>Phone</dt>
          <dd>{employee.mobile_number}</dd>
        </dl>
        <dl>
          <dt>Role</dt>
          <dd>{employee.designation}</dd>
        </dl>
        <dl>
          <dt>Location</dt>
          <dd>{employee.location}</dd>
        </dl>
        <div className="flex vcenter top-gutter-20 middle">
          <Link
            to={"/employerdetails?emp_id=" + employee.emp_id.replace("/", "_")}
            class="ant-btn-primary"
          >
            View / Edit Details
          </Link>
        </div>
      </article>
    ));
    return <div className="flex-row wrap mob-flex-row">{listItems}</div>;
  };
  return (
    <div className="employee emp-dashboard flex flex-column cell">
      <AdminHeader></AdminHeader>
      <div className="flex-row cell">
        <EmployeeNav></EmployeeNav>
        <div className="bodycontainer">
          <div className="flex flex-row">
            <h2 className="cell">
              Dashboard <small>Welcome to Sri Lakshmi Agro</small>
            </h2>
            <div className="flex-row mob-flex-row cell2">
              <div className="flex-row bottom-gutter-20 cell right-gutter-40 mob-right-gutter">
                <article className="totalproperties cell blue">
                  <h3>
                    <span class="material-symbols-outlined size-32">
                      real_estate_agent
                    </span>
                    Total Properties
                  </h3>
                  <strong>{plots.length}</strong>
                </article>
                <article className="totalproperties cell flex-row vcenter right-gutter">
                  <h3 className="flex-column cell">
                    <span class="material-symbols-outlined size-50 primary">
                      real_estate_agent
                    </span>
                    Available Plots
                  </h3>
                  <strong>{availablePlots.length}</strong>
                </article>
                <article className="totalproperties cell  flex-row vcenter left-gutter red">
                  <h3 className="flex-column cell">
                    <span class="material-symbols-outlined size-50 primary">
                      real_estate_agent
                    </span>
                    Plots Booked
                  </h3>
                  <strong>{bookedPlots.length}</strong>
                </article>

              </div>

            </div>
          </div>
          <div className="flex-column">
            <div className="flex-row top-gutter-40 cell">
              <article className="whitearticle cell  mob-grid-pan">
                <h3>My Team Members</h3>
                {/* <Table columns={columns} dataSource={employees} scroll={{ x: 250 }} pagination={false}/> */}
                <TeamGrid></TeamGrid>
              </article>
            </div>

              <div className="flex top-gutter-40 cell">
                <article className="whitearticle cell mob-grid-pan">
                  <h3>My Team Business</h3>
                  <TeamBusiness data={teeamBussinessHistory}></TeamBusiness>
                </article>
              </div>

          </div>
          <div className="flex-column">
          <div className="flex top-gutter-40 cell">
            <article className="whitearticle cell mob-grid-pan">
              <h3>My Commissions</h3>
              <EmpCommissions showButton={false}></EmpCommissions>
            </article>
          </div>
          <div className="flex-row top-gutter-40 cell">
            <article className="whitearticle cell  mob-grid-pan">
              <h3>My Business History</h3>
              <Table columns={paycolumns} dataSource={employeePaymentHistory} scroll={{ x: 250 }} pagination={false} />
            </article>
          </div>
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default EmployeeDashboard;
