import { createSlice } from '@reduxjs/toolkit'

export const employeeSlice = createSlice({
  name: 'employeeDetails',
  initialState: {
    id: 0,
    name: "",
  },
  reducers: {
    updateLoginDetails: (state, action) => {
      state = {...state, ...action.payload };
      return state;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateLoginDetails } = employeeSlice.actions;

export default employeeSlice.reducer;
