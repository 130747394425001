import React, { useState } from "react";
import { formatPrice } from "../../services/services";
import { Table } from "antd";

function TeamBusiness(props) {
  const { data } = props;

  const paycolumns = [
    {
      title: "Date",
      dataIndex: "created_at",
    },
  
    {
      title: "Agent ID",
      dataIndex: "emp_id",
    },
    {
      title: "Designation",
      dataIndex: "designation",
    },
    {
      title: "customer Name",
      dataIndex: "customerName",
    },
    {
      title: "project Name",
      dataIndex: "project_name",
    },
    {
        title: "Property Name",
        dataIndex: "property_name"
      },
    {
      title: "Plot Number",
      dataIndex: "plot_number",
    },
    {
      title: "Plot Price",
      dataIndex: "total_amount",
      render: (value) => formatPrice(value),
    },
    {
      title: "Due Price",
      dataIndex: "total_amount_due",
      render: (value) => formatPrice(value),
    },
    {
      title: "Plan",
      dataIndex: "plan_type",
    },
    {
      title: "Status",
      dataIndex: "plot_status",
    },
  ];

  return (
    <div className="emp-dashboard flex flex-column cell">
      <Table columns={paycolumns} dataSource={data} scroll={{ x: 250 }} pagination={false}/>
    </div>
  );
}

export default TeamBusiness;
